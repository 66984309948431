const french = {
  language: "fr",
  landingPageTitle1: "Bienvenue à",
  landingPageTitle2: "Ensemble, rénovons bien, habitons mieux",
  landingPageTitle3:
    "Renocity, la rénovation énergétique par quartier, avec un accompagnement de A à Z pour les propriétaires.",
  landingPageContent1:
    "Renocity est un projet de rénovation énergétique des habitations par quartier. Il est mené en collaboration avec les villes qui souhaitent réellement aider les citoyens dans leur trajectoire vers de meilleurs label. Renocity accompagne le propriétaire depuis le diagnostique de son habitation jusqu'à la supervision des travaux <span class='text-black font-semibold'>(sous certaines conditions)</span>, en passant par la collecte des offres et le conseil.",
  landingPageContent2:
    "C'est une opportunité unique pour un quartier et pour ses habitants. Et ça marche! Nous avons plus que doublé le taux de rénovation dans les quartiers avec lesquels nous avons travaillé.",
  landingPageButonText1: "Sint-Anna en Hof Somergem<br/>(terminé)",
  landingPageButonText2: "Haine-Saint-Pierre<br/>(en cours)",
  // header component
  headerTitle1: "Samen, goed renoveren, beter leven",
  headerButtonTitle: " Boek een gratis audit",
  headerGradientText: "Retour",
  headerGradientText1: "  What we renovate for you",
  headerGradientText2: " Voordelen",
  headerGradientText3: "Het proces",
  headerGradientText4: "Wie zijn we?",
  headerGradientText5: "FAQ",
  headerGradientText6: "Contacteer ons",
  // input Text
  inputPlaceholderText: "Typ jouw adres",
  inputButtonText: "Mijn diagnose",
  // homePage
  homePageBannerHeading: " Renoveer samen en bespaar op je energiekosten!",
  homePageBannertext1:
    "RENOCITY is een éénmalig renovatieproject speciaal ontwikkeld voor jouw buurt, in samenwerking met de stad Aalst, om jou te helpen betere EPC-labels voor je woning te bereiken (Label A of B).",
  homePageBannertext2:
    "Wij staan aan je zijde vanaf de diagnose van je woning tot de supervisie van de renovatiewerken.",
  homePageBannertext3: "2000 4000 8000",
  homePageBannertext4:
    " <span class='text-[#455259] text-sm lg:text-[17px] font-bold pl-4'>€ subsidy</span> ",
  homePageBannertext5:
    "Je wil een duurzame oplossing vinden voor de prijsstijging van de energiekosten? Je wil het comfort van jouw woning verbeteren? Je weet niet waar te beginnen om een betere EPC-label te halen?",
  homePageBannerImageBoxesText1: "ALLE",
  homePageBannerImageBoxesText2:
    "<span class='text-[#455259] text-[10px] lg:text-xs font-bold'>AUDITRAPPORTEN </span>",
  homePageBannerImageBoxesText3: "zijn al verzonden ",
  homePageBannerImageBoxesText4: "97",
  homePageBannerImageBoxesText5:
    "MENSEN hebben een audit geboekt met Renocity ",
  homePageBannerImageBoxesText6:
    "<span class='text-[#455259] text-[10px] lg:text-xs font-bold pr-0.5'>35</span>",
  homePageBannerImageBoxesText7: "available",
  homePageBannerImageBoxesText8:
    "<span  class='text-[#455259] text-[10px] lg:text-xs font-bold ''>More </span>",
  homePageBannerImageBoxesText9: "available",
  homePageCheckText1: "Gratis audit, scherpe prijzen, uitstekende kwaliteit",
  homePageCheckText2: "Energie-expert tot uw beschikking van A to Z",
  homePageCheckText3: "Hulp bij aanvragen subsidies",
  // our Client
  ourClientsTitle1: "Jane Doe",
  ourClientsTitle2: "3000 Leuven",
  ourClientstext1:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
  ourClientstext2: " At vero eos et accusam et justo duo dolores et ea rebum !",
  ourClientsTitle3: "Franck Lens",
  ourClientsTitle4: "3000 Leuven",
  ourClientstext3:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
  ourClientstext4:
    "  At vero eos et accusam et justo duo dolores et ea rebum !",
  ourClientsTitle5: "Luc Besson",
  ourClientsTitle6: "3000 Leuven",
  ourClientstext5:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam …",
  ourClientsTitle7: "Craig David",
  ourClientsTitle8: "3000 Leuven",
  ourClientstext6:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam …",
  ourClientTitle9: "Our Happy clients",
  ourClientstext7:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et",
  // Positive Points
  positivePointTitle: "Renocity vergeleken met klassieke renovatie",
  positivePointBox1: "Makkelijk en betrouwbaar proces",
  positivePointBox2: "Keuzevrijheid zoals klassieke renovatie",
  positivePointBox3: "Lagere energiekosten en meer comfort",
  positivePointBox4: "Hulp bij aanvragen subsidies",
  positivePointBox5: "Scherpe prijzen uitstekende kwaliteit",
  positivePointBox6: "Positieve ecologische Impact op jouw buurt",
  // process
  processTitle: "Een toekomstbestendig renovatietraject",
  procesText1:
    "De wetgeving in Vlaanderen neigt <span class='text-[#455259] font-bold'>naar strengere regels</span> met als doel dat woningen in 2050 een EPC-label A halen. De wetgeving vandaag bevestigt reeds tussentijdse en verplichte stappen richting EPC-label C en D,<span class='text-[#455259] font-bold'> voor zowel huur- als koopwoningen.</span>",
  procesText2:
    "Deze trend zal een negatieve <span class='text-[#455259] font-bold'> impact hebben op de waarde van onze woningen</span>als we het vereiste label niet halen.</span>",
  procesText3:
    "Daarnaast is <span class='text-[#455259] font-bold'>een gerenoveerde woning ook veel minder gevoelig aan stijgende energieprijzen.</span> ",
  procesTitle2: "LA LOUVIÈRE",
  procesTitle3: "Lorem ipsum dolor sit amet, consetetur",
  procesText4:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
  procesText5:
    " At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
  procesText6:
    " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
  procesText7: "Gemiddeld EPC in Lorem",
  procesText8: "Gemiddeld EPC in Lorem",
  // Your side
  yourSideTitle1: "Renocity, we staan aan jouw zijde van A tot Z",
  yourSideBox1Text1: "Check je woning online",
  yourSideBox1Text2: "01",
  yourSideBox1Text3: "Boek een gratis audit",
  yourSideBox1Text4: "02",
  yourSideBox1Text5: "Vraag een vrijblijvende offert aan",
  yourSideBox1Text6: "03",
  yourSideBox1LineText7: "BOEK EEN AUDIT VOOR EIND APRIL 2022",
  yourSideBox1Text8: "Doe mee met de buurtrenovatie",
  yourSideBox1Text9: "04",
  yourSideBox1LineText10: "BESLIS TOT EIND JULI 2022 (verlengd)",
  yourSideBox1Text11: "Renoveer",
  yourSideBox1Text12: "05",
  yourSideBox1LineText13: "RENOVEER VANAF SEPTEMBRE 2022",
  //  comingSoon_component
  comingSoonTitle1: "Ensemble, rénovons bien, habitons mieux",
  comingSoonTitle2: "PRE-ENREGISTREZ-VOUS POUR ETRE INFORME EN PRIORITE",
  comingSoonContent1:
    " Vous souhaitez partager votre avis et vos idées concernant le projet de rénovation urbaine de Haine-Saint-Pierre, vous aurez la possibilité de participer aux différents moments d'échanges et de concertation.",
  comingSoonFormTitle: "Je souhaite être recontacté pour",
  comingSoonBox1: "La rénovation énergétique de mon habitation (RENOCITY)",
  comingSoonBox2: "La réflexion sur la rénovation urbaine",
  comingSoonBox3: "Les deux",
  comingSoonEmail: "E-mail",
  comingSoonPlaceholher: "exemple@email.be",
  comingSoonFormText1:
    "En soumettant ce formulaire, je consens au traitement de mes informations personnelles conformément à ",
  comingSoonFormText2: "l’Avis de Confidentialité",
  comingSoonFormText3:
    "dans le but d'être contacté en relation avec le(s) élément(s) que j’ai sélectionné(s). Je peux retirer mon consentement à tout moment par courriel à <a class='text-[#007DDF] underline' href='mailto:AGC.ISM.Office@agc.com'>AGC.ISM.Office@agc.com</a>",

  // comingSoonCheckbox1:
  //   "Ask for consent to be contacted by email.- Lorem ipsum dolor sit amet, consetetur (FR)",
  // comingSoonCheckbox2Link:
  //   " <span class='text-[#007DDF] underline'>GDPR</span> ",
  // comingSoonCheckbox2:
  //   "consent - Lorem ipsum dolor sit amet, consetetur sadipscing elitr, se yam erat, sed diam voluptua et ea rebum. (FR)",
  comingSoonButton: "Envoyer",
  comigSoonSucessNotification: "Votre demande a été envoyée avec succès.",
  // comingSoon_modal
  comingSoonModalTitle: "General Data Protection Regulation (FR)",
  comingSoonModalContent1:
    "The GDPR is a European regulation on data protection that came into effect in May 2018. It establishes strict rules for the collection, processing, and retention of personal data of users of websites and mobile applications.",
  comingSoonModalContent2:
    "What is the GDPR? The GDPR is a European law that aims to protect the rights and freedoms of individuals with regard to the processing of their personal data. It applies to any company, organization or association that collects, processes or stores personal data of people residing in the European Union.",
  comingSoonModalContent3:
    " What personal data is covered by the GDPR? The GDPR covers all personal data, including names, addresses, phone numbers, email addresses, IP addresses, location data, and online identifiers.",
  comingSoonModalContent4:
    " What are the obligations of companies under the GDPR? Companies must obtain clear and explicit consent from users before collecting, processing, or storing their personal data. They must also inform users in a transparent manner about how their data is being used and about their data protection rights. Companies must also take technical and organizational measures to ensure the security of personal data and report data breaches within 72 hours of discovery. ",
  comingSoonModalContent5:
    "What are the rights of users under the GDPR? Users have the right to access, correct, delete or limit the processing of their personal data. They also have the right to object to the processing of their personal data for direct marketing purposes or for reasons related to their particular situation. Users also have the right to withdraw their consent at any time. Conclusion: The GDPR is an important European law that protects the rights and freedoms  ",
  comingSoonModalButton: "Fermer",
  commingSoonValidationStatus: "Merci de sélectionner une option",
  commingSoonValidationEmail: "Merci de remplir votre adresse e-mail",
  commingSoonValidationEmail2: "Adresse e-mail invalide",
  commingSoonValidationGDPR: "Pour continuer, vous devez accepter le RGPD.",
  // Policy
  policyHeading1: "Avis de Confidentialité de Renocity",
  policyText1:
    " Le Projet Renocity (le 'Projet') est un projet local unique, mené en collaboration avec votre municipalité, pour vous aider à améliorer le score EPC de votre habitation (vers des labels énergie plus performants) et à diminuer vos coûts énergétiques au moyen d'un parcours de rénovation simplifié et cohérent.",
  policyText2: "Le projet est géré par un consortium de trois partenaires:",
  policyText2List1:
    "AGC GLASS EUROPE S.A. <a class='text-black underline ' href='https://www.agc-glass.eu/en' target='_blank'>www.agc-glass.eu</a>",
  policyText2List2:
    "CLIMACT S.A.  <a class='text-black underline ' href='https://climact.com/en/' target='_blank'>www.climact.com</a>",
  policyText2List3:
    "LAB705 S.R.L.  <a class='text-black underline ' href='https://www.lab705.com/' target='_blank'>www.lab705.com</a>",
  policyText3:
    "Alors que les Partenaires du Consortium agissent ensemble en tant que responsables conjoints du traitement des données<sup>1</sup> en relation avec le Projet, AGC gère la Plateforme Renocity (<a class='text-[#007DDF] underline' href='https://renocity.be/' target='_blank'>www.renocity.be</a>)et agit en tant que responsable principal du traitement de vos données. Vous pouvez nous contacter par e-mail (<a class='text-[#007DDF] underline  ' href='mailto:AGC.ISM.Office@agc.com' >AGC.ISM.Office@agc.com</a>)pour toute question concernant votre vie privée.",
  policyText4:
    "La protection de vos Données Personnelles<sup>2</sup> est importante pour les Partenaires du Consortium (ci-après dénommés 'Renocity') et nous traitons votre vie privée avec le plus grand soin. Nous avons établi cet Avis de Confidentialité pour vous expliquer comment Renocity traite vos Données Personnelles. ",
  policyText5:
    "L'Avis de Confidentialité s'applique aux Données Personnelles que vous fournissez et que nous traitons<sup>3</sup> à votre sujet en tant que personne physique lorsque vous visitez notre site web et que vous vous inscrivez à notre bulletin d'information. ",
  policyText5List4:
    "<sup>1 </sup>dans la mesure où nous déterminons les finalités et les moyens du traitement de vos Données Personnelles.",
  policyText5List5:
    "<sup>2 </sup>Les 'Données Personnelles' sont des informations par lesquelles une personne physique est identifiable ou peut être identifiée. Renocity collecte, utilise et traite vos Données Personnelles afin de vous fournir les services, produits ou informations que vous demandez.",
  policyText5List6:
    "<sup>3 </sup>Le 'traitement' désigne tout ce que nous faisons avec vos Données personnelles, comme expliqué plus en détail dans le présent Avis de Confidentialité. Il peut s'agir d'actions telles que la collecte, l'enregistrement, l'organisation, la structuration, le stockage, l'adaptation ou la modification, l'extraction, la consultation, l'utilisation, la divulgation par transmission, la diffusion ou toute autre forme de mise à disposition, l'alignement ou la combinaison, la restriction, l'effacement ou la destruction.",
  policyHeading2:
    "Quelles sont les Données Personnelles que nous collectons dans le cadre du Projet ?",
  policyText6:
    "Nous traitons les informations (publiques) suivantes concernant votre habitation, telles qu'elles nous ont été fournies  <span class='text-black font-bold'>par votre municipalité</span>(ou autrement accessibles au public) dans le cadre du Projet Renocity : adresse, données géométriques du cadastre, état de rénovation tel qu'il ressort des statistiques nationales ou des données non nominatives agrégées.",
  policyText7:
    "Nous traitons également les Données Personnelles que<span class='text-black font-bold'>vous</span>nous fournissez lorsque vous interagissez avec nous par e-mail ou via la Plateforme Renocity, en remplissant et en nous envoyant un formulaire de contact ou de demande. Ces données personnelles comprennent :",
  policyText7List7:
    "Les données d'identité que vous fournissez (nom, prénom, adresse électronique, profession, etc.) à l'occasion de votre (pré)inscription sur la Plateforme Renocity ou lors de la réservation d'une visite d'auditeur/expert en énergie.",
  policyText7List8:
    "Toute Donnée Personnelle que vous pouvez partager avec Renocity ou un auditeur/expert en énergie dans le cadre d'une évaluation de votre habitation dans le contexte du Projet Renocity, qui peut inclure des données techniques sur la qualité de votre habitation afin de vous conseiller sur les produits et services, ainsi que des données sensibles telles que des données sociales/financières demandées, dans le but de donner une première évaluation de votre éligibilité aux subventions, aux prêts à taux zéro, etc.",
  policyText7List9:
    "Toutes les Données Personnelles mentionnées ci-dessus qui doivent être partagées avec des architectes et/ou des entrepreneurs (et d'autres professionnels du secteur de la rénovation, le cas échéant) dans le cas où vous décidez de poursuivre un projet de rénovation énergétique dans le cadre du Projet Renocity.",
  policyHeading3: "Pourquoi recueillons-nous vos Données Personnelles ?",
  policyText8:
    "Nous traitons vos Données Personnelles exclusivement aux fins décrites ci-dessous :",
  policyText8List10:
    "La création de votre compte utilisateur sur la Plateforme Renocity.",
  policyText8List11:
    "Vous informer sur le Projet (y compris l'objet du contrat, les avantages du Projet, la planification, les étapes clés, etc.)",
  policyText8List12:
    "Vous indiquer si votre habitation se trouve dans le périmètre du Projet Renocity (périmètre géographique). ",
  policyText8List13:
    "Vous fournir une estimation de l'état de rénovation de votre habitation, des avantages de la rénovation énergétique, des mesures de rénovation énergétiques possibles, de l'estimation du budget, de l'estimation des économies d’énergie, de l'estimation des primes, etc. afin de vous donner une première image (à confirmer par un diagnostic/un audit énergétique sur place) d'une éventuelle trajectoire de rénovation.",
  policyText8List14:
    "La réservation et la réalisation d'une visite d'audit  ou de diagnostic énergétique à votre domicile afin de vous conseiller sur la trajectoire de rénovation énergétique que vous pourriez envisager dans les mois et années à venir ",
  policyText8List15:
    "Le partage de vos informations sur la base du 'besoin de savoir' aux fins indiquées entre les Partenaires du Consortium (et leurs sous-traitants) afin d'assurer une coordination harmonieuse des processus d'audit/diagnostic énergétique, d'offre et de rénovation énergétique. ",
  policyText8List16:
    "Le partage de vos coordonnées et des informations relatives au Projet avec des architectes et/ou des entrepreneurs sélectionnés (et d'autres professionnels du secteur de la rénovation, le cas échéant), uniquement dans le cas où vous décidez de lancer un projet de rénovation énergétique sur la base des résultats de l'audit/diagnostic énergétique sur site ",
  policyText8List17:
    "Vous accompagner au mieux dans la rénovation énergétique de votre habitation dans le cadre du Projet Renocity.",
  policyText8List18:
    "Vous tenir informé des prochaines étapes du Projet Renocity pour votre habitation et dans votre quartier par le biais d'un e-mail périodique et/ou d'une lettre d'information (dont vous pouvez vous désabonner à tout moment).",
  policyText9:
    "La base juridique sur laquelle nous traitons les informations que vous nous fournissez est votre consentement spécifique et éclairé (qui est recueilli via le formulaire de consentement que vous avez signé lors de votre inscription au Projet et/ou à certains moments choisis tout au long du Projet",
  policyText10:
    "Vous êtes libre de retirer ce consentement à tout moment en envoyant un e-mail à <a class='text-[#007DDF] underline ' href='mailto:AGC.ISM.Office@agc.com' >AGC.ISM.Office@agc.com</a>, après quoi nous cesserons de traiter vos informations.",
  policyText11:
    "La base juridique sur laquelle nous traitons les informations concernant votre habitation que nous recevons de votre municipalité (ou via d'autres sources publiques) et pour le partage entre les Partenaires du Consortium est l'intérêt légitime des Partenaires du Consortium (combiné à l'intérêt public de la municipalité) à s'engager dans le Projet Renocity.",
  policyHeading3A: "Avec qui partageons-nous vos Données Personnelles ?",
  policyText11A:
    "Renocity fournira l'accès ou partagera les Données Personnelles selon les besoins avec des tiers, tels que des prestataires de services de confiance, des auditeurs, des consultants, des architectes et des entrepreneurs qui ont accès aux systèmes de Renocity ou qui fournissent des services à Renocity, et d'autres personnes si la loi l'exige ou si c'est nécessaire pour répondre à votre demande.",
  policyText11B:
    "Les prestataires de services tiers comprennent les prestataires de services qui fournissent une assistance technique et administrative pour la Plateforme Renocity et les systèmes informatiques sous-jacents. Ces prestataires de services fournissent leurs services à partir de sites situés à l'intérieur et à l'extérieur de l'Espace économique européen ('EEE'), y compris les États-Unis.",
  policyText11C:
    "Renocity ne partagera vos Données Personnelles qu'avec des tiers auxquels Renocity a contractuellement empêchés d'utiliser ou de divulguer les Données Personnelles, sauf si cela est nécessaire pour fournir des services en notre nom ou pour se conformer à des exigences légales ou pour répondre aux besoins de l’exercice du droit.",
  policyHeading4:
    "Que se passe-t-il si vos Données Personnelles sont transférées en dehors de l'Espace économique européen (EEE) ?",
  policyText12:
    "Renocity peut avoir besoin de transférer vos données vers des pays en dehors de l'EEE, où la protection de la vie privée est moins développée, y compris les États-Unis.",
  policyText13:
    "Renocity s'appuie sur les mécanismes juridiques disponibles pour permettre le transfert légal de Données Personnelles à travers les frontières. Dans la mesure où Renocity s'appuie sur les Clauses Contractuelles Standard<sup>4</sup> (également appelées Clauses Modèles), pour autoriser le transfert de Données Personnelles, Renocity se conformera à ces exigences, y compris en cas de conflit entre ces exigences et le présent Avis de Confidentialité. ",
  policyText14:
    "En l'absence des garanties appropriées susmentionnées, Renocity peut, lorsque les lois applicables en matière de protection des données (y compris le RGPD) le permettent, invoquer une dérogation applicable à la situation spécifique en question (par exemple, le consentement explicite de la personne concernée, la nécessité d'exécuter un accord, la nécessité de défendre des droits légaux).",
  policyText14List19:
    "<sup>4 </sup>La Commission européenne peut décider que des clauses contractuelles types offrent des garanties suffisantes en matière de protection des données pour les données devant être transférées au niveau international. Elle a publié des ensembles de clauses types couvrant différents scénarios de transfert.",
  policyHeading5: "Comment sécurisons-nous vos Données Personnelles ?",
  policyText15:
    "Nous utilisons des mesures de sécurité technologiques et opérationnelles raisonnables et appropriées pour protéger vos données à caractère personnel contre tout accès non autorisé ou toute utilisation illégale. Ces mesures comprennent:",
  policyText15List20:
    "Assurer la sécurité physique et numérique de notre équipement et dispositifs en utilisant une protection par mot de passe et un cryptage appropriés si nécessaire ;",
  policyText15List21:
    "Assurer la sécurité physique de nos bureaux et autres sites ;",
  policyText15List22:
    "Maintenir une politique de protection des données pour nos employés et les former à la protection des données ; et",
  policyText15List23:
    "Limiter l'accès à vos Données Personnelles aux personnes de Renocity qui ont besoin de les utiliser dans le cadre de leur travail.",
  policyText16:
    "Toutefois, en raison de la nature des communications par internet, nous ne pouvons pas garantir que la transmission de vos Données Personnelles soit sécurisée.",
  policyHeading6:
    "Pendant combien de temps conservons-nous vos Données Personnelles ?",
  policyText17:
    "Les Données Personnelles que vous fournissez à Renocity ne sont conservées que pendant la durée raisonnablement nécessaire aux fins pour lesquelles elles ont été collectées, en tenant compte de notre besoin de nous conformer à des obligations contractuelles ou légales ainsi qu'à des fins statistiques et de recherche liées au Projet. Cela signifie que nous pouvons conserver vos Données Personnelles pendant une période raisonnable après la fin de votre participation au Projet (maximum 7 ans). Après cette période, vos Données Personnelles seront supprimées de tous les systèmes de Renocity sans préavis.",
  policyHeading7: "Quels sont les droits que vous pouvez exercer ?",
  policyText18:
    "Sur la base de la législation belge et européenne applicable en matière de protection de la vie privée, et pour autant que vous répondiez aux exigences fixées par cette législation, vous pouvez nous contacter pour exercer les droits suivants :",
  policyText18List24:
    "Retirer votre consentement au traitement de vos données personnelles.",
  policyText18List25:
    "Demander l'accès à vos données personnelles, les corriger, les supprimer ou les restreindre.",
  policyText18List26:
    "S'opposer à tout traitement à des fins de marketing direct.",
  policyText18List27:
    "S'opposer à tout traitement fondé sur notre intérêt légitime.",
  policyText18List28:
    "Demander la portabilité<sup>5</sup> de vos Données Personnelles que vous nous avez communiquées et que nous avons traitées.",
  policyText18List29:
    "Déposer une plainte auprès de l'autorité de contrôle de l'État membre de votre résidence habituelle, de votre lieu de travail ou du lieu où la violation présumée de la législation applicable en matière de protection des données a été commise.",
  policyText19:
    "Vous pouvez envoyer vos demandes d'exercice des droits susmentionnés, accompagnées d'une preuve de votre identité, par courrier électronique à l'adresse <a class='text-[#007DDF] underline ' href='mailto:AGC.ISM.Office@agc.com' >AGC.ISM.Office@agc.com</a>",
  policyText20:
    "Nous examinerons la recevabilité de votre demande sur la base de la législation belge et européenne applicable en matière de protection de la vie privée. Nous vous informerons ensuite, en principe dans un délai d'un mois, si nous pouvons répondre positivement à votre demande.",
  policyText20List30:
    "<sup>5 </sup>Le droit à la portabilité des données vous permet d'obtenir les données qu'un responsable du traitement détient sur vous et de les réutiliser à vos propres fins. Les données doivent être reçues 'dans un format structuré, couramment utilisé et lisible par machine'.",
  policyHeading8:
    "Comment Renocity peut-elle modifier cet Avis de Confidentialité ?",
  policyText21:
    "Au fur et à mesure que Renocity développe et améliore son site web, ou que les exigences légales changent, nous pouvons être amenés à mettre à jour cet Avis de Confidentialité. Cet Avis de Confidentialité peut être modifié de temps à autre sans préavis. Nous vous encourageons à consulter régulièrement cet Avis de Confidentialité pour prendre connaissance des changements éventuels. La date de la dernière version sera indiquée au bas de l'Avis de Confidentialité.",
  policyHeading9: "Pour toute question",
  policyText22:
    "Si vous avez des questions sur le présent Avis de Confidentialité ou sur la protection des données chez Renocity, vous pouvez contacter notre Délégué à la protection des données par courrier :",
  policyText23:
    "Renocity - AGC Glass Europe, Attn. Privacy Professional, Avenue Jean Monnet 4, 1348 Louvain-la-Neuve ou par e-mail à <a class='text-[#007DDF] underline ' href='mailto:AGC.ISM.Office@agc.com' >AGC.ISM.Office@agc.com</a>",
  policyText24:
    "Vous avez également le droit d'introduire une réclamation auprès de l'autorité de contrôle de l'État membre dans lequel vous résidez habituellement, dans lequel vous travaillez ou dans lequel la violation présumée de la législation applicable en matière de protection des données a été commise :",
  policyText25:
    "En Belgique, l'Autorité de contrôle est la suivante : Autorité belge de protection des données, Rue de la Presse 35 / Drukpersstraat 35, 1000 Bruxelles / 1000 Bruxelles, Tel. +32 2 274 48 00, Fax +32 2 274 48 35 <a class='text-[#007DDF] underline 'href='mailto:contact@apd-gba.be'>contact@apd-gba.be</a> ",

  // Cookie Policy
  cookieHeading: "UTILISATION DES COOKIES",
  cookieHeading1: "À propos de cette Politique",
  cookieHeading1List1:
    "La présente Politique en matière de cookies (<span class='text-black font-bold'>'Politique'</span>) explique comment ACG et les sociétés de son groupe (<span class='text-black font-bold'>'AGC',' nous'</Span> et <span class='text-black font-bold'>'nos'</span>) font usage de cookies et de technologies similaires afin de vous reconnaître lorsque vous visitez notre site Web à l'adresse  <a class='text-[#007DDF] underline' href='https://renocity.be/' target='_blank'>https://renocity.be/ </a>(<span class='text-black font-bold'>'Site Web'</span>). Il explique ce que sont ces technologies et pourquoi nous les utilisons, ainsi que vos droits relatifs au contrôle de l'utilisation que nous en faisons.",
  cookieHeading1List2:
    " Veuillez prendre le temps de lire attentivement cette Politique. Si vous avez des questions ou commentaires, merci de contacter notre ISM Office via  <a class='text-[#007DDF] underline ' href='mailto:AGC.ISM.Office@agc.com' >AGC.ISM.Office@agc.com</a>",
  cookieHeading2: "Que sont les cookies ?",
  cookieHeading2List3:
    "Les cookies sont de petits fichiers de textes placés sur votre ordinateur ou appareil mobile lorsque vous visitez un site Web. Les cookies sont largement utilisés par les propriétaires de sites Web afin de faire fonctionner ces derniers, de travailler plus efficacement, ou pour assurer la transmission d'information.",
  cookieHeading2List4:
    "Les cookies ont de nombreuses fonctionnalités différentes, comme celle vous permettant de naviguer efficacement entre les pages, de mémoriser vos préférences et, en général, d'améliorer l'expérience utilisateur. Les cookies peuvent également aider à s'assurer que les publicités que vous visionnez en ligne sont plus pertinentes pour vous et vos centres d'intérêt. ",
  cookieHeading2List5:
    "Les cookies déposés par le propriétaire du site (dans ce cas, AGC) sont appelés 'cookies internes'. Les cookies déposés par des parties autres que le propriétaire du site sont appelés 'cookies tiers'. Les cookies tiers activent des applications ou fonctionnalités de tiers sur ou via le site Web (ex: comme la publicité, du contenu interactif et d'analyse). Les parties qui déposent ces cookies tiers peuvent reconnaître votre ordinateur à la fois lorsqu'il visite le site Web en question et également lorsqu'il visite certains autres sites Web.",
  cookieHeading3: "Combien de temps restent les cookies ?",
  cookieHeading3List6:
    "Les cookies peuvent rester sur votre ordinateur ou appareil mobile durant des périodes temps variables. Certains cookies sont des 'cookies de session', ce qui signifie qu'ils n'existent que lorsque votre navigateur est ouvert et qu'ils sont automatiquement supprimés lorsque vous fermez votre navigateur ou quittez l'application. D'autres cookies sont des 'cookies persistants', ce qui signifie qu'ils survivent après que votre navigateur ou application est fermé et qu'ils peuvent être utilisés par des sites Web ou des applications afin de reconnaître votre ordinateur lorsque vous ouvrez à nouveau votre navigateur ou application.  La durée de vie des cookies utilisés sur notre Site Web est expliquée plus en détail dans le tableau ci-dessous.",
  cookieHeading4: "Pourquoi utilisons-nous des cookies ?",
  cookieHeading5: "Liste des cookies",
  cookieHeading5Text1:
    "Un cookie est un petit fichier de données (fichier texte) qu'un site Web, lorsqu'il est consulté par un utilisateur, demande à votre navigateur de stocker sur votre appareil afin de mémoriser des informations vous concernant, telles que vos préférences linguistiques ou informations de connexion. Nous configurons ces cookies appelés cookies internes. Nous utilisons également des cookies tiers, qui sont des cookies d'un domaine différent de celui du site Web que vous consultez, pour nos efforts de publicité et de marketing. Plus concrètement, nous utilisons des cookies et d'autres technologies de suivi selon les finalités suivantes:",
  cookieHeading6: "Cookies strictement nécessaires",
  cookieHeading6Text2:
    "Ces cookies sont nécessaires au fonctionnement du site Web et ne peuvent pas être désactivés dans nos systèmes. Ils sont généralement établis en tant que réponse à des actions que vous avez effectuées et qui constituent une demande de services, telles que la définition de vos préférences en matière de confidentialité, la connexion ou le remplissage de formulaires. Vous pouvez configurer votre navigateur afin de bloquer ou être informé de l'existence de ces cookies, mais certaines parties du site Web peuvent être affectées. Ces cookies ne stockent aucune information d’identification personnelle.",
  cookieTableTitle1: "Cookies strictement nécessaires",
  cookieTableHeading7: "Sous-groupe de cookies",
  cookieTableHeading8: "Cookies",
  cookieTableHeading9: "Cookies utilisés",
  cookieTableHeading10: "Durée de vie",
  cookieTableHeading10Text3:
    "<a class='text-[#007DDF] underline' href='https://www.agc-glass.eu/en' target='_blank'>www.agc-glass.eu</a>",
  cookieTableHeading10Text4: "agc-glass.eu",
  cookieTableHeading10Text5:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/agc.gestmax.fr' target='_blank'>agc.gestmax.fr</a>",
  cookieTableHeading10Text6:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/cookies/OptanonAlertBoxClosed' target='_blank'>OptanonAlertBoxClosed , OptanonConsent</a>",
  cookieTableHeading10Text7:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/cookies/_dc_gtm_UA-xxxxxxxx' target='_blank'>_dc_gtm_UA-xxxxxxxx</a>",
  cookieTableHeading10Text8: "PHPSESSID",
  cookieTableHeading10Text9: "Cookies internes",
  cookieTableHeading10Text10: "Cookies internes",
  cookieTableHeading10Text11: "Cookies tiers",
  cookieTableHeading10Text12: "364 Jours, 364 Jours",
  cookieTableHeading10Text13: "quelques secondes",
  cookieTableHeading10Text14: "Session",
  cookieHeading11: "Cookies de performance",
  cookieHeading11Text15:
    "Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic, afin de mesurer et d’améliorer les performances de notre site Web. Ils nous aident également à identifier les pages les plus / moins visitées et d’évaluer comment les visiteurs naviguent sur le site Web. Toutes les informations collectées par ces cookies sont agrégées et donc anonymisées. Si vous n'acceptez pas ces cookies, nous ne serons pas informé de votre visite sur notre site.",
  cookieTableTitle2: "Cookies de performance",
  cookieHeading13: "Sous-groupe de cookies",
  cookieHeading14: "Cookies",
  cookieHeading15: "Cookies utilisés",
  cookieHeading16: "Durée de vie",
  cookieHeading16Text16: "agc-glass.eu",
  cookieHeading16Text17:
    "<a class='text-[#007DDF] underline' href='https://www.agc-glass.eu/en' target='_blank'>www.agc-glass.eu</a>",
  cookieHeading16Text18:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/nr-data.net' target='_blank'>nr-data.net</a>",
  cookieHeading16Text19:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/cookies/_ga' target='_blank'>_gid , _ga</a>",
  cookieHeading16Text20:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/cookies/_ga_CHXGVTZGC2' target='_blank'>_ga_CHXGVTZGC2 , ln_or</a>",
  cookieHeading16Text21: "JSESSIONID",
  cookieHeading16Text22: "Cookies internes",
  cookieHeading16Text23: "Cookies internes",
  cookieHeading16Text24: "Cookies tiers",
  cookieHeading16Text25: "quelques secondes, 729 Jours",
  cookieHeading16Text26: "quelques secondes, quelques secondes",
  cookieHeading16Text27: "Session",
  cookieHeading17: "Cookies de fonctionnalité",
  cookieHeading17Text28:
    "Ces cookies permettent d’améliorer et de personnaliser les fonctionnalités du site Web. Ils peuvent être activés par nos équipes, ou par des tiers dont les services sont utilisés sur les pages de notre site Web. Si vous n'acceptez pas ces cookies, une partie ou la totalité de ces services risquent de ne pas fonctionner correctement.",
  cookieTableTitle3: "Cookies de fonctionnalité",
  cookieTableHeading18: "Sous-groupe de cookies",
  cookieTableHeading19: "Cookies",
  cookieTableHeading20: "Cookies utilisés",
  cookieTableHeading21: "Durée de vie",
  cookieTableHeading21Text29:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/vimeo.com' target='_blank'>vimeo.com</a>",
  cookieTableHeading21Text30:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/agc.gestmax.fr' target='_blank'>agc.gestmax.fr</a>",
  cookieTableHeading21Text31: "vuid, __cf_bm",
  cookieTableHeading21Text32: "AGC Glass Europe_alertcookies",
  cookieTableHeading21Text33: "Cookies tiers",
  cookieTableHeading21Text34: "Cookies tiers",
  cookieTableHeading21Text35: "729 Jours, quelques secondes",
  cookieTableHeading21Text36: "59 Jours",
  cookieHeading22: "Cookies pour une publicité ciblée",
  cookieHeading23Text37:
    "Ces cookies peuvent être mis en place au sein de notre site Web par nos partenaires publicitaires. Ils peuvent être utilisés par ces sociétés pour établir un profil de vos intérêts et vous proposer des publicités pertinentes sur d'autres sites Web. Ils ne stockent pas directement des données personnelles, mais sont basés sur l'identification unique de votre navigateur et de votre appareil Internet. Si vous n'autorisez pas ces cookies, votre publicité sera moins ciblée.",
  cookieTableTitle4: "Cookies pour une publicité ciblée",
  cookieTableHeading24: "Sous-groupe de cookies",
  cookieTableHeading25: "Cookies",
  cookieTableHeading26: "Cookies utilisés",
  cookieTableHeading27: "Durée de vie",
  cookieTableHeading27Text38: "agc-glass.eu",
  cookieTableHeading27Text39:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/youtube.com' target='_blank'>youtube.com</a>",
  cookieTableHeading27Text40:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/www.facebook.com' target='_blank'>www.facebook.com</a>",
  cookieTableHeading27Text41:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/linkedin.com' target='_blank'>linkedin.com</a>",
  cookieTableHeading27Text42:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/www.linkedin.com' target='_blank'>www.linkedin.com</a>",
  cookieTableHeading27Text43:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/ads.linkedin.com' target='_blank'>ads.linkedin.com</a>",
  cookieTableHeading27Text44:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/cookies/_fbp' target='_blank'>_fbp</a>",
  cookieTableHeading27Text45: "VISITOR_INFO1_LIVE, CONSENT, YSC",
  cookieTableHeading27Text46:
    "li_gc, lang, UserMatchHistory, bcookie, AnalyticsSyncHistory, lidc",
  cookieTableHeading27Text47: "bscookie",
  cookieTableHeading27Text48: "lang",
  cookieTableHeading27Text49: "Cookies internes",
  cookieTableHeading27Text50: "Cookies tiers",
  cookieTableHeading27Text51: "Cookies tiers",
  cookieTableHeading27Text52: "Cookies tiers",
  cookieTableHeading27Text53: "Cookies tiers",
  cookieTableHeading27Text54: "Cookies tiers",
  cookieTableHeading27Text55: "89 Jours",
  cookieTableHeading27Text56: "179 Jours, 729 Jours, Session",
  cookieTableHeading27Text57: "Session",
  cookieTableHeading27Text58:
    "729 Jours, Session, 29 Jours, 730 Jours, 29 Jours, quelques secondes",
  cookieTableHeading27Text59: "730 Jours",
  cookieTableHeading27Text60: "Session",
  cookieHeading28:
    "Quid des autres technologies de suivi comme les balises Web ?",
  cookieHeading28Text61:
    "Les cookies ne sont pas la seule façon de reconnaître ou de suivre les visiteurs d'un site Web. Nous pouvons de temps en temps utiliser d'autres technologies similaires, comme des balises Web (parfois appelées 'pixels de suivi' ou 'gifs invisibles'). Il s'agit de petits fichiers graphiques qui contiennent un identifiant unique qui nous permettent de reconnaître quand quelqu'un a visité notre site Web ou ouvert un e-mail que nous avons-nous lui avons envoyé. Cela nous permet, par exemple, de surveiller les habitudes de trafic des utilisateurs d'une page de notre Site Web à une autre, de livrer ou de communiquer avec des cookies, de savoir si vous venez sur notre Site Web à partir d'une publicité en ligne affichée sur un site Web tiers, pour améliorer les performances de notre Site et mesurer le succès des campagnes de marketing par e-mail. Dans de nombreux cas, ces technologies dépendent de cookies pour fonctionner correctement. Dès lors, refuser les cookies va nuire à leur fonctionnement.",
  cookieHeading29: "Quid de la publicité ciblée ?",
  cookieHeading29Text62:
    "Les tiers peuvent placer des cookies sur votre ordinateur ou appareil mobile pour proposer de la publicité via notre Site Web. Ces entreprises peuvent utiliser des informations relatives à vos visites sur ce Site Web ainsi que sur d'autres sites web afin de fournir des publicités pertinentes quant à des biens et services qui sont susceptibles de vous intéresser. Ils peuvent également utiliser une technologie qui permet de mesurer l'efficacité des publicités. Cela peut être accompli en utilisant des cookies ou des balises Web afin de collecter des informations sur vos visites de ce Site Web et sur d'autres sites et pour fournir des publicités pertinentes sur les produits et services susceptibles de vous intéresser. Les informations recueillies dans le cadre de ce processus ne nous/leur permettent pas d'identifier vos noms, coordonnées ou autres informations d'identification personnelles, sauf ",
  cookieHeading30: "Comment puis-je contrôler les cookies ?",
  cookieHeading30Text63:
    "Vous avez le droit de décider d'accepter ou de refuser les cookies. Vous pouvez exercer vos préférences en cliquant sur les liens de désactivation appropriés et fournis dans l’onglet « paramètres de confidentialité » apparaissant sur votre écran. La préférence de désactivation est valable pendant 30 jours. Après cette période, vous pouvez revenir sur votre décision.",
  cookieHeading30Text64:
    "Vous pouvez définir ou modifier les contrôles de votre navigateur Web afin d'accepter ou de refuser les cookies. Si vous choisissez de refuser les cookies, vous pourrez toujours utiliser notre Site Web bien que votre accès à certaines fonctionnalités et domaines de notre Site Web puissent être limités. Etant donné que le moyen par lequel vous pouvez refuser les cookies à travers le contrôle de votre navigateur Web varie d'un navigateur à l'autre, vous devriez consulter le menu d'aide de votre navigateur pour recevoir de plus amples informations.",
  cookieHeading30Text65:
    "En outre, la plupart des réseaux de publicité vous offrent un moyen de vous retirer de la publicité ciblée. Si vous souhaitez de plus amples renseignements, veuillez visitez  <a class='text-[#007DDF] underline' href='http://www.aboutads.info/choices/' target='_blank'>http://www.aboutads.info/choices/</a> (en anglais) ou  <a class='text-[#007DDF] underline' href='http://www.youronlinechoices.com/fr/' target='_blank'>http://www.youronlinechoices.com/fr/</a>.",
  cookieHeading31: "Les cookies envoyés par le passé",
  cookieHeading31Text66:
    "Si vous avez désactivé un ou plusieurs cookies, nous pouvons toujours utiliser les informations recueillies par ces cookies avant leur désactivation. Cependant, nous cessons de recueillir de nouvelles informations via le cookie désactivé.",
  cookieHeading32: "Obtenir plus d'informations sur les cookies",
  cookieHeading32Text67:
    "Pour obtenir plus d'informations sur les cookies, y compris des explications sur la façon dont les cookies sont placés sur votre appareil, ou sur la façon de les gérer et de les supprimer, visitez <a class='text-[#007DDF] underline' href=' http://www.allaboutcookies.org/fr/' target='_blank'> http://www.allaboutcookies.org/fr/</a>.",
  cookieHeading33: "Mise à jour de cette Politique",
  cookieHeading33Text68:
    "Nous pouvons mettre à jour cette Politique de temps à autre en réponse à l'évolution des développements juridiques, techniques ou commerciaux. Lorsque nous actualiserons notre Politique, nous prendrons les mesures appropriées pour vous informer, conformément à l'importance des changements que nous apportons. Nous obtiendrons votre consentement à tout changement important apporté à cette Politique si cela est requis par les lois sur la protection des données applicables.",
  cookieHeading33Text69:
    "Vous pouvez voir quand cette Politique a été mise à jour pour la dernière fois en vous reportant à la date de la dernière mise à jour affichée en haut de cette Politique.",
  // result page
  resultTitle1: "Simulatie",
  resultTitle2: "Albertlaan 39 3210 Leuven",
  resultText1: "Build Year:",
  resultText2: "1954",
  resultText3: "Number of people living:",
  resultText4: "4",
  resultText5: "Surface:",
  resultText6: "209m",
  resultText7: "Heated levels:",
  resultText8: "3",
  resultText9: "House typology:",
  resultText10: "Detached",
  resultText11: "Basement:",
  resultText12: "Yes",
  resultText13: "Attic:",
  resultText14: "No",
  resultText15: "High energy consumer:",
  resultText16: "Electric car",
  resultText17: "Meer info",
  resultText18:
    "JE EPC-score lijkt zeer slecht. Jouw woning leidt zeer veel energieverlies.",
  resultText19: "Pas jouw gegevens aan om betere aanbevelingen te krijgen.",
  resultText20: "Walls insulation",
  resultText21: "Cavity (<2000)",
  resultText22: "Heating system",
  resultText23: "Gas (condensing)",
  resultText24: "Solar panels",
  resultText25: "Yes, 10 panels",
  resultText26: "Windows & doors",
  resultText27: "Old double (<2000)",
  resultText28: "Roof insulation",
  resultText29: "Exterior (>2000 & < 2010)",
  resultText30: "Ventilation",
  resultText31: "System C+",
  resultText32: "Huidig comfort",
  resultButton1: "Gegevens aanpassen",
  resultText33:
    "Kies je renovatiepakket richting Label A of Label B of pas het aan.",
  resultButton2: "Pakket label A",
  resultButton3: "Pakket label B",
  resultButton4: "Personaliseer",
  resultText34:
    "Deze combinatie van maatregelen werd geselecteerd om <span class='text-[#455259] font-bold text-sm'>zo kostenefficiënt mogelijk het EPC label B te bereiken. Het vereist niettemin de bevestiging van onze energie-expert</span> aangezien onze berekeningen gebaseerd zijn op ongevalideerde aannames en deze daarom enkel als indicatief kunnen beschouwd worden. Ook kan het voorgestelde pakket mogelijks niet consistent zijn met de maatregelen nodig voor een toekomstig traject naar label A. Onze energie-expert kan je een gedetailleerd renovatieadvies geven, rekening houdende met de werkelijke toestand van jouw huis, jouw huidig verbruik en andere vereisten zoals comfort bijvoorbeeld.",
  resultText35: "Subsidies section",
  resultText36:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea",
  resultButton5: "Subsidies 1",
  resultButton6: "Subsidies 2",
  resultText37: "ONS AANBEVOLEN PAKKET VOOR LABEL B",
  resltSelect1: "sorteren op",
  resultText38: "Solar panels",
  resultText39: "Subsidies",
  resultText40: "- 1.295 €",
  resultText41: "5.100 €",
  resultText42: "Before subidies",
  resultText43: "Savings",
  resultText44: "Complexity",
  resultText45: "Comfort",
  resultText46: "Window double",
  resultText47: "Subsidies",
  resultText48: "- 751 €",
  resultText49: "28.200 €",
  resultText50: "Before subidies",
  resultText51: "Roof insulation",
  resultText52: "Subsidies",
  resultText53: "- 441 €",
  resultText54: "8.400 €",
  resultText55: "Before subidies",
  resultText56: "Wall insulation",
  resultText57: "Subsidies",
  resultText58: "- 950 €",
  resultText59: "7.600 €",
  resultText60: "Before subidies",
  resultText61: "Renovation loan",
  resultText62: "Rate",
  resultText63: "Green loan",
  resultText64: "5,00 %",
  resultText65: "Loan amount",
  resultText66: "40.547,35 €",
  resultText67: "Term",
  resultText68: "12 months",
  resultText69: "60 months",
  resultText70:
    "<span class='text-[#007DDF] text-[22px] font-bold'>442,50 €</span>/month",
  resultText71: "Attention, borrowing money also costs money.",
  resultText72: "Jouw toekomstige EPC-Label",
  resultText73: "Wat je momenteel teveel betaalt",
  resultText74: "- 476 €",
  resultText75: "Jouw toekomstig comfort",
  resultText76: "+34 %",
  resultText77: "Renting value",
  resultText78: "950,00 €",
  resultText79: "Yearly savings",
  resultText80: "+34 %",
  resultText81: "Selling value",
  resultText82: "978 kg.",
  resultText83: "CO2 savings",
  resultText84: "Onze voorlopige schatting voor jouw woning",
  resultText85: "Begroting ",
  resultText86: "41.700 € ",
  resultText87: "Fluvius Subsidies",
  resultText88: "- 2.487 € ",
  resultText89: "Subsidies La Louvière ",
  resultText90: "- 50% ",
  resultText91: "Totaal (btw incl.)",
  resultText92: "-5.774 €",
  resultText93: "Boek een gratis en vrijblijvend audit",
  resultText94:
    "Bovenstaande informatie is <span class='text-sm text-[#455259] font-bold'>indicatief</span> en zal tijdens de audit door onze energiedeskundige worden bevestigd.",
  resultText95:
    " Deze eerste subsidieraming omvat alleen Fluvius-subsidies. Je zal je investering waarschijnlijk <span class='text-sm text-[#455259] font-bold'> nog verder verminderen dankzij andere subsidies en premies</span> (het kan oplopen tot 40% of zelfs meer)",
  resultText96: "Jouw volgende stappen",
  resultText97:
    "Tot eind april, kan je een audit reserveren met een energie-expert. Die zal de diagnose gedetailleerd afwerken.",
  resultText98: "Check je woning online",
  resultText99: "Boek een gratis audit",
  resultText100: "Vraag een vrijblijvende offerte aan",
  resultText101: "Doe mee met de buurtrenovatie",
  resultText102: "Renoveer",
  // footer
  footerText1: "Designed & developed par Agifly",
  footerText2: "Avis de Confidentialité",
  footerText3: "Politique Cookies",
};
export default french;
