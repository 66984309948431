import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useLocation } from "react-router-dom";
// images
// import Lion from "../../assets/images/comingSoon/lion.png";
import Aalst from "../../assets/images/comingSoon/lalou-logo-grey.svg";

interface FooterInterface {}

const Footer: FC<FooterInterface> = () => {
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  // const Navigate = useNavigate();
  const location = useLocation();
  const currentPath = window.location.pathname;

  const Window = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div >
      {/* footer_images */}
      {currentPath !== "/" ? (
        <div className="bg-[#FBFBFB] w-full  flex justify-center items-center px-5 py-5">
          {/* <div className="pr-10">
            <img
              src={Lion}
              alt="lion"
              className="h-16 md:h-[95px] cursor-pointer"
              onClick={() => window.open("https://www.vlaanderen.be/", "_open")}
            />
          </div> */}
          <div>
            <img
              src={Aalst}
              alt="Aalst"
              className="h-6 md:h-[50px] cursor-pointer"
              onClick={() => window.open("https://www.lalouviere.be/", "_open")}
            />
          </div>
        </div>
      ) : null}
      {/* footer_content */}
      <div className="w-full bg-gradient-to-r from-[#A3ED5E] to-[#0480DB]">
        <div className="flex justify-center w-full  py-2.5 px-2.5 ">
          <p className="text-xs text-white ">{state?.language?.footerText1}</p>
          <span className="border-r border-r-white pl-2 "></span>
          <span
            onClick={() => {
              dispatch({
                type: "SET_PREVIOUS_PAGE",
                payload: [...state?.previousPage, location?.pathname],
              });
              window.open(
                `/privacy/${state?.language?.language.toLowerCase()}`,
                "_blank"
              );
              // Navigate(`/privacy/${state?.language?.language.toLowerCase()}`);
              Window();
            }}
            className="cursor-pointer text-xs text-white pl-2"
          >
            {state?.language?.footerText2}
          </span>
          <span className="border-r border-r-white border-r-solid pl-2 "></span>
          <span
            onClick={() => {
              dispatch({
                type: "SET_PREVIOUS_PAGE",
                payload: [...state?.previousPage, location?.pathname],
              });
              window.open(
                `/cookie/${state?.language?.language.toLowerCase()}`,
                "_blank"
              );
              // Navigate(`/cookie/${state?.language?.language.toLowerCase()}`);
              Window();
            }}
            className="cursor-pointer text-xs text-white pl-2"
          >
            {state?.language?.footerText3}
          </span>
        </div>
      </div>
    </div>
  );
};
export default Footer;
