import { Markup } from "interweave";
// Method to return value through index.
export const getValue = (state: any, pageIndex: number, index: number): any => {
  // if condition is to check exist or not
  if (
    state?.homePageData?.pages?.length !== 0 &&
    state?.homePageData?.pages?.length !== undefined
  ) {
    return state?.homePageData?.pages[pageIndex]?.page_contents?.length !== 0 &&
      state?.homePageData?.pages[pageIndex]?.page_contents[index]?.value ? (
      <Markup
        content={state?.homePageData?.pages[pageIndex]?.page_contents[index]?.value}
      />
    ) : (
      ""
    );
  }
  // else to return empty string
  else {
    return "";
  }
};
