import React, { FC, useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { ScreenSize } from "../../utils/windowDimension";
import { setLanguageHandler } from "../../context/actions/language";
import {
  useLocation,
  //  useNavigate
} from "react-router-dom";
import { GetAPIService } from "../../context/services";
// languages
import French from "../../languages/fr";
import Dutch from "../../languages/nl";
import { Helmet } from "react-helmet";

interface LanguageSelectorInterface {}

const LanguageSelector: FC<LanguageSelectorInterface> = () => {
  // location to get page detail and navigation for redirecting to another page
  const location = useLocation();
  // const navigate = useNavigate();
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  // to Get ScreenSize
  const screenSize = ScreenSize();
  // toggle state to show and hide language option
  const [toggle, setToggle] = useState(false);
  // state to show default selected language
  const [selectedLanguage, setSelectedLanguage] = useState("");
  console.log(selectedLanguage);
  // dynamic language handler
  const languageSelector = (languageId: number) => {
    dispatch({
      type: "SET_BACKOFFICE_CONTENT_LOADER",
      payload: true,
    });
    GetAPIService(`/overhead/37/${languageId}`)
      .then((response) => {
        dispatch({
          type: "SET_BACKOFFICE_CONTENT",
          payload: response?.data?.data,
        });
        dispatch({
          type: "SET_BACKOFFICE_CONTENT_LOADER",
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SET_BACKOFFICE_CONTENT_LOADER",
          payload: false,
        });
        console.log("error", error);
      });
  };
  
  // dynamic language useEffect
  useEffect(() => {
    languageSelector(2);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedLanguage(state?.language?.language.toUpperCase());
    // eslint-disable-next-line
  }, [location?.pathname]);

  // handler for language width for large and small screens
  const languageWidthHandler = () => {
    // large screen condition
    if (screenSize?.width > 1023) {
      if (state?.language?.language === "fr") {
        return toggle ? "170px" : "80px";
      } else {
        return toggle ? "170px" : "80px";
      }
    }
    // small screen condition
    else {
      return toggle ? "120px" : "60px";
    }
  };

  // select language handler to change language from selected options
  const selectLanguageHandler = (
    selectedLanguage: string,
    languageData: Object
  ) => {
    setLanguageHandler(languageData, dispatch);
    setSelectedLanguage(selectedLanguage);
    setToggle(!toggle);
    // document.documentElement.lang = selectedLanguage.toLowerCase();
    if (
      !location?.pathname?.includes("cookie") ||
      !location?.pathname?.includes("privacy")
    ) {
      // window.open("/" + selectedLanguage.toLowerCase(), "_self");
    }
    // condition for cookie and policy page to show content after updating language.
    if (location?.pathname?.includes("cookie")) {
      // navigate(`/cookie/${selectedLanguage?.toLowerCase()}`);
      window.open(`/cookie/${selectedLanguage?.toLowerCase()}`, "_self");
    } else if (location?.pathname?.includes("privacy")) {
      // navigate(`/privacy/${selectedLanguage?.toLowerCase()}`);
      window.open(`/privacy/${selectedLanguage?.toLowerCase()}`, "_self");
    }
  };

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: state.language.language.toLowerCase() }}
      />
      <div className="w-full py-5 px-4 header-container z-10 flex">
        <div
          className={`container mx-auto flex ${
            location?.pathname !== "/" ? "justify-between" : "justify-end"
          } items-center`}
        >
          {/* right side language selector */}
          <div className="flex justify-end">
            <div
              className={` flex items-center language-selector`}
              style={{
                minWidth: languageWidthHandler(),
              }}
            >
              <div
                className={`py-1 lg:py-2 lg:pl-8 font-[600] flex items-center cursor-pointer z-10 ${
                  !toggle && "pr-1"
                }`}
                onClick={() => setToggle(!toggle)}
              >
                {/* to show selected language option*/}
                <div className="bg-white">
                  <p className="w-[60px] text-center pt-[10px] pb-[8px] border border-[#97979748] rounded px-4 h-fit text-[#455259] font-medium">
                    {state?.language?.language.toUpperCase()}
                  </p>
                </div>
              </div>
            </div>
            {/* to show unselected language option*/}
            {toggle && (
              <div
                className={`${
                  toggle
                    ? "mt-[5px] mr-[16px] lg:mt-[9px] lg:mr-[28px] pl-2 absolute"
                    : "hidden"
                }`}
              >
                <p
                  className="w-10 text-center text-[#455259] font-[600] py-2 h-fit cursor-pointer font-medium"
                  onClick={() => {
                    state?.language?.language.toUpperCase() === "FR"
                      ? selectLanguageHandler("NL", Dutch)
                      : selectLanguageHandler("FR", French);
                    // language handler for Api call to get data
                    state?.language?.language.toUpperCase() === "FR"
                      ? languageSelector(1)
                      : languageSelector(2);
                  }}
                >
                  {state?.language?.language.toUpperCase() === "FR"
                    ? "NL"
                    : "FR"}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LanguageSelector;
