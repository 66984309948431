const german = {
  language: "nl",
  landingPageTitle1: "Welkom bij",
  landingPageTitle2: "Samen, goed renoveren, beter leven",
  landingPageTitle3:
    "Renocity, energierenovatie per wijk, met begeleiding van A tot Z voor eigenaren.",
  landingPageContent1:
    "Renocity is een residentieel energierenovatieproject per wijk. Het wordt uitgevoerd in samenwerking met steden die burgers echt op weg willen helpen naar betere labels. Renocity begeleidt de eigenaar vanaf de diagnose van zijn woning tot aan de begeleiding van de werken <span class='text-black font-semibold'>(onder bepaalde omstandigheden)</span>, via het verzamelen van offertes en advies.",
  landingPageContent2:
    "Dit is een unieke kans voor een wijk en voor haar bewoners. En het werkt! We hebben het renovatiepercentage in de buurten waarmee we hebben gewerkt meer dan verdubbeld.",
  landingPageButonText1: "Sint-Anna en Hof Somergem<br/> (beëindigd)",
  landingPageButonText2: "Haine-Saint-Pierre<br/> (lopend)",
  // header component
  headerTitle1: "Samen, goed renoveren, beter leven",
  headerButtonTitle: " Boek een gratis audit",
  headerGradientText: "Terug",
  headerGradientText1: "  What we renovate for you",
  headerGradientText2: " Voordelen",
  headerGradientText3: "Het proces",
  headerGradientText4: "Wie zijn we?",
  headerGradientText5: "FAQ",
  headerGradientText6: "Contacteer ons",
  // input Text
  inputPlaceholderText: "Typ jouw adres",
  inputButtonText: "Mijn diagnose",
  // home page
  homePageBannerHeading: " Renoveer samen en bespaar op je energiekosten!",
  homePageBannertext1:
    "RENOCITY is een éénmalig renovatieproject speciaal ontwikkeld voor jouw buurt, in samenwerking met de stad Aalst, om jou te helpen betere EPC-labels voor je woning te bereiken (Label A of B).",
  homePageBannertext2:
    "Wij staan aan je zijde vanaf de diagnose van je woning tot de supervisie van de renovatiewerken.",
  homePageBannertext3: "2000 4000 8000",
  homePageBannertext4:
    " <span class='text-[#455259] text-sm lg:text-[17px] font-bold pl-4'>€ subsidy</span> ",
  homePageBannertext5:
    "Je wil een duurzame oplossing vinden voor de prijsstijging van de energiekosten? Je wil het comfort van jouw woning verbeteren? Je weet niet waar te beginnen om een betere EPC-label te halen?",
  homePageBannerImageBoxesText1: "ALLE",
  homePageBannerImageBoxesText2:
    "<span class='text-[#455259] text-[10px] lg:text-xs font-bold'>AUDITRAPPORTEN </span>",
  homePageBannerImageBoxesText3: "zijn al verzonden ",
  homePageBannerImageBoxesText4: "97",
  homePageBannerImageBoxesText5:
    "MENSEN hebben een audit geboekt met Renocity ",
  homePageBannerImageBoxesText6:
    "<span class='text-[#455259] text-[10px] lg:text-xs font-bold pr-0.5'>35</span>",
  homePageBannerImageBoxesText7: "available",
  homePageBannerImageBoxesText8:
    "<span  class='text-[#455259] text-[10px] lg:text-xs font-bold ''>More </span>",
  homePageBannerImageBoxesText9: "available",
  homePageCheckText1: "Gratis audit, scherpe prijzen, uitstekende kwaliteit",
  homePageCheckText2: "Energie-expert tot uw beschikking van A to Z",
  homePageCheckText3: "Hulp bij aanvragen subsidies",
  // our Client
  ourClientsTitle1: "Jane Doe",
  ourClientsTitle2: "3000 Leuven",
  ourClientstext1:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
  ourClientstext2: " At vero eos et accusam et justo duo dolores et ea rebum !",
  ourClientsTitle3: "Franck Lens",
  ourClientsTitle4: "3000 Leuven",
  ourClientstext3:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
  ourClientstext4:
    "  At vero eos et accusam et justo duo dolores et ea rebum !",
  ourClientsTitle5: "Luc Besson",
  ourClientsTitle6: "3000 Leuven",
  ourClientstext5:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam …",
  ourClientsTitle7: "Craig David",
  ourClientsTitle8: "3000 Leuven",
  ourClientstext6:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam …",
  ourClientTitle9: "Our Happy clients",
  ourClientstext7:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et",
  // Positive Points
  positivePointTitle: "Renocity vergeleken met klassieke renovatie",
  positivePointBox1: "Makkelijk en betrouwbaar proces",
  positivePointBox2: "Keuzevrijheid zoals klassieke renovatie",
  positivePointBox3: "Lagere energiekosten en meer comfort",
  positivePointBox4: "Hulp bij aanvragen subsidies",
  positivePointBox5: "Scherpe prijzen uitstekende kwaliteit",
  positivePointBox6: "Positieve ecologische Impact op jouw buurt",

  // process
  processTitle: "Een toekomstbestendig renovatietraject",
  procesText1:
    "De wetgeving in Vlaanderen neigt <span class='text-[#455259] font-bold'>naar strengere regels</span> met als doel dat woningen in 2050 een EPC-label A halen. De wetgeving vandaag bevestigt reeds tussentijdse en verplichte stappen richting EPC-label C en D,<span class='text-[#455259] font-bold'> voor zowel huur- als koopwoningen.</span>",
  procesText2:
    "Deze trend zal een negatieve <span class='text-[#455259] font-bold'> impact hebben op de waarde van onze woningen</span>als we het vereiste label niet halen.</span>",
  procesText3:
    "Daarnaast is <span class='text-[#455259] font-bold'>een gerenoveerde woning ook veel minder gevoelig aan stijgende energieprijzen.</span> ",
  procesTitle2: "LA LOUVIÈRE",
  procesTitle3: "Lorem ipsum dolor sit amet, consetetur",
  procesText4:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
  procesText5:
    " At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
  procesText6:
    " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
  procesText7: "Gemiddeld EPC in Lorem",
  procesText8: "Gemiddeld EPC in Lorem",
  // Your side
  yourSideTitle1: "Renocity, we staan aan jouw zijde van A tot Z",
  yourSideBox1Text1: "Check je woning online",
  yourSideBox1Text2: "01",
  yourSideBox1Text3: "Boek een gratis audit",
  yourSideBox1Text4: "02",
  yourSideBox1Text5: "Vraag een vrijblijvende offert aan",
  yourSideBox1Text6: "03",
  yourSideBox1LineText7: "BOEK EEN AUDIT VOOR EIND APRIL 2022",
  yourSideBox1Text8: "Doe mee met de buurtrenovatie",
  yourSideBox1Text9: "04",
  yourSideBox1LineText10: "BESLIS TOT EIND JULI 2022 (verlengd)",
  yourSideBox1Text11: "Renoveer",
  yourSideBox1Text12: "05",
  yourSideBox1LineText13: "RENOVEER VANAF SEPTEMBRE 2022",
  //  comingSoon_component
  comingSoonTitle1: "Samen, goed renoveren, beter leven",
  comingSoonTitle2: "PRE-REGISTREER OM PRIORITEIT TE WORDEN GEÏNFORMEERD",
  comingSoonContent1:
    " Vous avez un bien à Haine-Saint-Pierre et vous souhaitez bénéficier d'un diagnostique gratuit, de l'accompagnement Renocity et des primes de rénovation énergétiques, vous serez averti en priorité du lancement de la plateforme (gratuite et sans engagement).",
  comingSoonFormTitle: "Ik wil graag gecontacteerd worden voor",
  comingSoonBox1: "De energierenovatie van mijn woning",
  comingSoonBox2: "De reflectie op stadsvernieuwing",
  comingSoonBox3: "Beide",
  comingSoonEmail: "E-mail",
  comingSoonPlaceholher: "voorbeeld@email.be",
  comingSoonFormText1:
    "Door dit formulier in te dienen, stem ik in met de verwerking van mijn persoonlijke gegevens in overeenstemming met de ",
  comingSoonFormText2: "Privacyverklaring",
  comingSoonFormText3:
    "om gecontacteerd te worden met betrekking tot het item/de items die ik heb geselecteerd. Ik kan mijn toestemming op elk moment intrekken door een e-mail te sturen naar <a class='text-[#007DDF] underline' href='mailto:AGC.ISM.Office@agc.com'>AGC.ISM.Office@agc.com</a>",

  // comingSoonCheckbox1:
  //   "Ask for consent to be contacted by email.- Lorem ipsum dolor sit amet, consetetur (NL)",
  // comingSoonCheckbox2Link:
  //   " <span class='text-[#007DDF] underline'>GDPR</span> ",
  // comingSoonCheckbox2:
  //   "Ask for consent to be contacted by email.- Lorem ipsum dolor sit amet, consetetur (NL)",
  comingSoonButton: "Verstuur",
  comigSoonSucessNotification: "Uw aanvraag is succesvol verzonden.",
  // comingSoon_modal
  comingSoonModalTitle: "General Data Protection Regulation (NL)",
  comingSoonModalContent1:
    "The GDPR is a European regulation on data protection that came into effect in May 2018. It establishes strict rules for the collection, processing, and retention of personal data of users of websites and mobile applications.",
  comingSoonModalContent2:
    "What is the GDPR? The GDPR is a European law that aims to protect the rights and freedoms of individuals with regard to the processing of their personal data. It applies to any company, organization or association that collects, processes or stores personal data of people residing in the European Union.",
  comingSoonModalContent3:
    " What personal data is covered by the GDPR? The GDPR covers all personal data, including names, addresses, phone numbers, email addresses, IP addresses, location data, and online identifiers.",
  comingSoonModalContent4:
    " What are the obligations of companies under the GDPR? Companies must obtain clear and explicit consent from users before collecting, processing, or storing their personal data. They must also inform users in a transparent manner about how their data is being used and about their data protection rights. Companies must also take technical and organizational measures to ensure the security of personal data and report data breaches within 72 hours of discovery. ",
  comingSoonModalContent5:
    "What are the rights of users under the GDPR? Users have the right to access, correct, delete or limit the processing of their personal data. They also have the right to object to the processing of their personal data for direct marketing purposes or for reasons related to their particular situation. Users also have the right to withdraw their consent at any time. Conclusion: The GDPR is an important European law that protects the rights and freedoms  ",
  comingSoonModalButton: "Sluiten",
  commingSoonValidationStatus: "Gelieve uw rede voor contact te selecteren.",
  commingSoonValidationEmail: "Gelieve uw email in te vullen.",
  commingSoonValidationEmail2: "Ongeldig e-mailadres",
  commingSoonValidationGDPR:
    "Om verder te gaan dient u akkoord te gaan met de GDPR.",
  // Privacy Policy
  policyHeading1: " Renocity Privacy Notice",
  policyText1:
    " The Renocity project (the « Project ») is a one-time local project, in collaboration with your municipality, to help you improve the EPC score of your home (towards label A or B) and save on energy costs by means of simplified and coherent renovation trajectory.",
  policyText2: "The Project is managed by a consortium of three partners:",
  policyText2List1:
    "AGC GLASS EUROPE S.A. <span class='text-black underline ' >www.agc-glass.eu</span>",
  policyText2List2:
    "CLIMACT S.A.  <span class='text-black underline'>www.climact.com</span>",
  policyText2List3:
    "LAB705 S.R.L.  <span class='text-black  underline'>www.lab705.com</span>",
  policyText3:
    "While the Consortium Partners act together as joint Data Controllers<sup>1</sup> in relation to the Project, AGC manages the Renocity Platform (<a class='text-[#007DDF] underline' href='https://renocity.be/' target='_blank'>www.renocity.be</a>) and acts as lead controller for the processing of your data. You can contact us via email (<a class='text-[#007DDF] underline  ' href='mailto:AGC.ISM.Office@agc.com' >AGC.ISM.Office@agc.com</a>) for any question regarding your privacy.",
  policyText4:
    "The protection of your Personal Data<sup>2</sup> is of importance for the Consortium Partners (together hereinafter “Renocity”) and we treat your privacy with utmost care.",
  policyText5:
    "We have established this Privacy Notice to explain you how Renocity treats your Personal Data. The Privacy Notice applies to the Personal Data that you provide and that we process<sup>3</sup> about you as a natural person when you visit our website and register for our newsletter.",
  policyText5List4:
    "<sup>1 </sup>to the extent that we determine the purposes and means of the processing of your Personal Data.",
  policyText5List5:
    "<sup>2 </sup>“Personal Data” is information through which a natural person is identifiable or may be identified. Renocity collects, uses and processes your Personal Data in order to provide you with services, products or information that you request.",
  policyText5List6:
    "<sup>3 </sup>“Processing” means everything we do with your Personal Data as further explained in this Privacy Notice. It may include actions such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.",

  policyHeading2: "Which Personal Data do we collect as part of the Project?",
  policyText6:
    "We process the following (public) information of your home, as provided to us <span class='text-black font-bold'>by your municipality</span> (or otherwise publicly available) in the context of the Renocity Project: address, geometric data from cadastre, renovation status as hypothesis deducted from national statistics or aggregated non nominative data’s.",
  policyText7:
    "We also process the Personal Data that <span class='text-black font-bold'>you</span> provide to us when you interact with us via e-mail or the Renocity Platform, by completing and sending us a contact or request form. These Personal Data include:",
  policyText7List7:
    "Identity data you provide (surname, first name, email address, occupation, etc.) at the occasion of your (pre)registration on the Renocity Platform or when booking an energy auditor/expert visit.",
  policyText7List8:
    "Any Personal Data that you may share with Renocity or an energy auditor/expert in the context of an evaluation of your home within the context of the Renocity Project, which may include technical quality data on your home in order to advise on products and services as well as sensitive data such as social/financial data as requested in order to give a first assessment on your eligibility for subsidies, zero interest loans, etc.",
  policyText7List9:
    "Any Personal Data as stated above that is required to be shared with architects and/or contractors (and other professionals in the renovation sector as the case may be) companies in the event that you decide to pursue a renovation project as a result of the Renocity Project.",
  policyHeading3: "Why do we collect your Personal Data?",
  policyText8:
    "We process your Personal Data exclusively for the purposes described hereunder:",
  policyText8List10: "Creating your user account on the Renocity Platform.",
  policyText8List11:
    "Informing you about the Project (incl. object of contract, benefits of project, planning, key steps, etc.)",
  policyText8List12:
    "Indicating if your home is in the scope of the Renocity project (geographical scope).",
  policyText8List13:
    "Providing you with estimate of your home renovation status, renovation benefits, possible renovation measures you can take, budget estimate, savings estimate, subsidies estimate, etc. in order to give you a first image (to be confirmed by an expertise/audit onsite) of a possible renovation trajectory.",
  policyText8List14:
    "Booking and implementing an audit visit to your home in order to advise you on the renovation trajectory you may envision in the coming months and years ",
  policyText8List15:
    "Sharing your information on, a “need to know” basis for the stated purposes between the Consortium Partners (and their subcontractors) in order to ensure a smooth coordination of the energy audit/diagnosis, offer and renovation processes.",
  policyText8List16:
    "Sharing your contact details and Project related information with select architects and/or contractors (and other professionals in the renovation sector as the case may be), only in case you decide to initiate an energy renovation project based on the outcome of the onsite energy audit/diagnosis ",
  policyText8List17:
    "Supporting you the best way possible for the energy renovation of your home in the context of the Renocity project.",
  policyText8List18:
    "Keeping you informed on the next steps of the Renocity project for your home and in your district via periodical e-mail and or newsletter (from which you can unsubscribe at any time).",
  policyText9:
    "The legal basis on which we process the information you provide to us is your specific, informed consent (which is collected via the consent form you signed when enrolling into the Project and/or at selected moments throughout the Project",
  policyText10:
    "You are free to withdraw this consent at any time by sending an e-mail to <a class='text-[#007DDF] underline ' href='mailto:AGC.ISM.Office@agc.com' >AGC.ISM.Office@agc.com</a>, upon which we will cease to process your information.",
  policyText11:
    "The legal basis on which we process information regarding your home that we receive from your municipality (or via other public sources) and for the sharing among Consortium Partners is the legitimate interest of the Consortium Partners (combined with the public interest of the municipality) to engage in the Renocity Project.",
  policyHeading3A: "With whom do we share your Personal Data?",
  policyText11A:
    "Renocity will provide access to or share Personal Data on an as-needed basis with third parties, such as trusted service providers, auditors, consultants, architects and contractors who are granted access to Renocity’s systems or which provide services to Renocity, and with government agencies or authorities, competent courts and others as required by law or if it is necessary to fulfill your request.",
  policyText11B:
    "Third party service providers include service providers that provide technical and administrative support for the Renocity Platform and underlying IT systems.  These service providers provide their services from locations within and outside of the European Economic Area (“EEA”), including the USA.",
  policyText11C:
    "Renocity will only share your Personal Data with third parties whom Renocity has contractually restricted from using or disclosing the Personal Data except as necessary to perform services on our behalf or to comply with legal requirements.",
  policyHeading4:
    "What if your Personal Data is transferred outside the European Economic Area (EEA)?",
  policyText12:
    "Renocity may need to transfer your data to countries outside the EEA, where the privacy protection is less developed, including the USA.",
  policyText13:
    "Renocity relies on available legal mechanisms to enable the legal transfer of Personal Data across borders. To the extent that Renocity relies on the Standard Contractual<sup>4</sup> Clauses (also called the Model Clauses), to authorize the transfer of Personal Data, Renocity will comply with those requirements, including where there may be a conflict between those requirements and this Privacy Notice.",
  policyText14:
    "In the absence of the aforementioned appropriate safeguards, Renocity may where permitted by applicable data protection laws (including the GDPR) rely on a deroga-tion applicable to the specific situation at hand (e.g. the data subjects’ explicit con-sent, the necessity for the performance of an agreement, the necessity for the de-fense of legal claims).",
  policyText14List19:
    "<sup>4 </sup>The European Commission can decide that standard contractual clauses offer sufficient safeguards on data protection for the data to be transferred internationally. It has published sets of such standard clauses covering different transfer scenarios.",
  policyHeading5: "How do we secure your Personal Data?",
  policyText15:
    "We use reasonable and appropriate technological and operational security measures to keep your Personal Data protected against any unauthorized access or unlawful use. Such measures include:",
  policyText15List20:
    "Ensuring the physical and digital security of our equipment and devices by using appropriate password protection and encryption where necessary;",
  policyText15List21:
    "Ensuring the physical security of our offices and other sites;",
  policyText15List22:
    "Maintaining a data protection policy for, and delivering data protection training to, our employees; and",
  policyText15List23:
    "Limiting access to your Personal Data to those at Renocity who need to use them in the course of their work.",
  policyText16:
    "However, due to the nature of internet communications, we cannot guarantee or warrant that the transmission of your Personal Data to us is secure.",
  policyHeading6: "For how long do we keep your Personal Data ?",
  policyText17:
    "The Personal Data you provide to Renocity is only kept for as long as it is reasonably necessary for the purposes for which it is collected, taking into account our need to comply with contractual or legal obligations as well as statistical and research purposes related to the Project. This means that we may retain your Personal Data for a reasonable period after your participation in the Project has ceased (maximum 5 years). After this period, your Personal Data will be deleted from all systems of Renocity without notice.",
  policyHeading7: "What rights can you exercise?",
  policyText18:
    "Based on the applicable Belgian and European privacy legislation, and provided that you meet the requirements set by this legislation, you can contact us to exercise the following rights:",
  policyText18List24:
    "Withdrawing your consent for the Processing of your Personal Data.",
  policyText18List25:
    "Seeking access to, correcting, deleting or restricting your Personal Data.",
  policyText18List26: "Objecting to any Processing for direct marketing.",
  policyText18List27:
    "Objecting to any Processing based on our legitimate interest",
  policyText18List28:
    "Seeking the portability<sup>5</sup> of your Personal Data, which you have communicated to us and that we have processed.",
  policyText18List29:
    "Lodging a complaint with the supervisory authority in the European Member State of your habitual residence, your place of work or where the alleged infringement of applicable data protection legislation occurred.",
  policyText19:
    "You can send your requests to exercise your above mentioned rights, together with evidence of your identity, by email at <a class='text-[#007DDF] underline ' href='mailto:AGC.ISM.Office@agc.com' >AGC.ISM.Office@agc.com</a>",
  policyText20:
    "We will examine the admissibility of your request based on the applicable Belgian and European privacy legislation. We will then inform you, in principle within a month, if we can answer positively to your request.",
  policyText20List30:
    "<sup>5 </sup>The right to data portability allows you to obtain data that a data controller holds on you and to reuse it for your own purposes. The data must be received “in a structured, commonly used and machine-readable format”.",
  policyHeading8: "How may Renocity change this Privacy Notice?",
  policyText21:
    "As Renocity expands and improves its website, or as legal requirements change, we may need to update this Privacy Notice. This Privacy Notice may be modified from time to time without prior notice. We encourage you to review this Privacy Notice on a regular basis for any changes. The date of the latest version will be identified at the bottom of the Privacy Notice.",
  policyHeading9: "Do you have any questions?",
  policyText22:
    "If you have any questions about this Privacy Notice or data protection at Renocity, you can contact our Data Protection Officer either by mail:",
  policyText23:
    "Renocity – AGC Glass Europe, Attn. Privacy Professional, Avenue Jean Monnet 4,1348 Louvain-la-Neuve or by e-mail at <a class='text-[#007DDF] underline ' href='mailto:AGC.ISM.Office@agc.com' >AGC.ISM.Office@agc.com</a>",
  policyText24:
    "You also have the right to lodge a complaint with the supervisory authority in the European Member State of your habitual residence, your place of work or where the alleged infringement of applicable data protection legislation occurred:",
  policyText25:
    "In Belgium, the Supervisory Authority is: Belgian Data Protection Authority, Rue de la Presse 35 / Drukpersstraat 35, 1000 Bruxelles / 1000 Brussel, Tel. +32 2 274 48 00, Fax +32 2 274 48 35 <a class='text-[#007DDF] underline 'href='mailto:contact@apd-gba.be'>contact@apd-gba.be</a> ",
  // Cookie Policy
  cookieHeading: "GEBRUIK VAN COOKIES",
  cookieHeading1: "Over deze Policy",
  cookieHeading1List1:
    "Deze Cookie Policy (<span class='text-black font-bold'>'Policy'</span>) legt uit hoe AGC en haar groepsentiteiten (gezamenlijk <span class='text-black font-bold'>“AGC”, 'wij', 'ons'</span> en <span class='text-black font-bold'>'onze'</span>) gebruik maken van cookies en gelijkaardige technologieën om u te herkennen wanneer u onze website bezoekt op <a class='text-[#007DDF] underline' href='https://renocity.be/' target='_blank'>https://renocity.be/ </a> (<span class='text-black font-bold'>Website'</span>). Het legt uit wat deze technologieën zijn en ",
  cookieHeading1List2:
    "Neem alstublieft de tijd om deze Policy grondig te lezen. Indien u vragen of opmerkingen zou hebben, gelieve onze ISM office te contacteren via <a class='text-[#007DDF] underline ' href='mailto:AGC.ISM.Office@agc.com' >AGC.ISM.Office@agc.com</a>",
  cookieHeading2: "Wat zijn cookies?",
  cookieHeading2List3:
    "Cookies zijn kleine tekstbestanden die op uw computer of mobiel apparaat worden geplaatst wanneer u een website bezoekt. Cookies worden veelvuldig gebruikt door eigenaars van websites om hun websites te laten werken, of om deze efficiënter te laten werken, alsook om informatie te rapporteren.",
  cookieHeading2List4:
    "Cookies hebben veel verschillende functies, zoals u toelaten om efficiënt te navigeren tussen pagina's, het onthouden van uw voorkeuren, en in het algemeen, om de gebruikerservaring te verbeteren. Cookies kunnen eveneens helpen om ervoor te zorgen dat de advertenties die u online ziet relevanter zijn voor u en uw interesses.  ",
  cookieHeading2List5:
    "Cookies die geplaatst worden door de eigenaar van de website (in dit geval, AGC) worden 'eerste partijcookies' genoemd. Cookies die geplaatst worden door anderen dan de eigenaar van de website worden 'derde partijcookies' genoemd. Derde partijcookies laten toe om eigenschappen of functionaliteiten van derde partijen beschikbaar te stellen op of via de website (bv. advertenties, interactieve content en analyses). De partijen die deze derde partijcookies plaatsen kunnen uw computer herkennen zowel wanneer u de website in kwestie bezoekt als wanneer u bepaalde andere websites bezoekt. ",
  cookieHeading3: "Hoe lang worden cookies bewaard?",
  cookieHeading3List6:
    "Cookies kunnen op uw computer of mobiel apparaat bewaard blijven gedurende verschillende tijdspannes. Sommige cookies zijn 'sessiecookies', wat betekent dat ze enkel bestaan wanneer uw website browser openstaat en automatisch verwijderd worden eens u uw browser sluit of de applicatie verlaat. Andere cookies zijn 'permanente cookies', wat betekent dat ze blijven bestaan wanneer uw browser of applicatie gesloten is en door websites of applicaties gebruikt kunnen worden om uw computer te herkennen wanneer u later opnieuw uw browser of applicatie opent. De duur van de cookies die wij op onze Website gebruiken, wordt hieronder in de tabel gedetailleerd beschreven.",
  cookieHeading4: "Waarom gebruiken we cookies?",
  cookieHeading5: "Cookielijst",
  cookieHeading5Text1:
    "Een cookie is een klein tekstbestandje met gegevens dat een website (wanneer een gebruiker deze bezoekt) op uw apparaat wil opslaan om zodoende informatie over u, zoals uw taalvoorkeur of inloginformatie, te kunnen herinneren. Deze cookies worden door ons ingesteld en heten interne cookies. We gebruiken ook externe cookies. Dit zijn cookies van een ander domein dan waar u zich bevindt. We gebruiken ze voor advertentie- en marketingdoeleinden. In het bijzonder gebruiken we cookies en andere tracking-technologieën voor de volgende doeleinden:",
  cookieHeading6: "Strikt noodzakelijke cookies",
  cookieHeading6Text2:
    "Deze cookies zijn nodig anders werkt de website niet. Deze cookies kunnen niet worden uitgeschakeld. In de meeste gevallen worden deze cookies alleen gebruikt naar aanleiding van een handeling van u waarmee u in wezen een dienst aanvraagt, bijvoorbeeld uw privacyinstellingen registreren, in de website inloggen of een formulier invullen. U kunt uw browser instellen om deze cookies te blokkeren of om u voor deze cookies te waarschuwen, maar sommige delen van de website zullen dan niet werken. Deze cookies slaan geen persoonlijk identificeerbare informatie op.",
  cookieTableTitle1: "Strikt noodzakelijke cookies",
  cookieTableHeading7: "Cookiesubgroep",
  cookieTableHeading8: "Cookies",
  cookieTableHeading9: "Gebruikte cookies",
  cookieTableHeading10: "Levensduur",
  cookieTableHeading10Text3:
    "<a class='text-[#007DDF] underline' href='https://www.agc-glass.eu/en' target='_blank'>www.agc-glass.eu</a>",
  cookieTableHeading10Text4: "agc-glass.eu",
  cookieTableHeading10Text5:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/agc.gestmax.fr' target='_blank'>agc.gestmax.fr</a>",
  cookieTableHeading10Text6:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/cookies/OptanonAlertBoxClosed' target='_blank'>OptanonAlertBoxClosed , OptanonConsent</a>",
  cookieTableHeading10Text7:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/cookies/_dc_gtm_UA-xxxxxxxx' target='_blank'>_dc_gtm_UA-xxxxxxxx</a>",
  cookieTableHeading10Text8: "PHPSESSID",
  cookieTableHeading10Text9: "Direct",
  cookieTableHeading10Text10: "Direct",
  cookieTableHeading10Text11: "Indirect",
  cookieTableHeading10Text12: "364 Dagen, 364 Dagen",
  cookieTableHeading10Text13: "Enkele seconden",
  cookieTableHeading10Text14: "Sessie",
  cookieHeading11: "Prestatiecookies",
  cookieHeading11Text15:
    "Deze cookies stellen ons in staat bezoekers en hun herkomst te tellen zodat we de prestatie van onze website kunnen analyseren en verbeteren. Ze helpen ons te begrijpen welke pagina’s het meest en minst populair zijn en hoe bezoekers zich door de gehele site bewegen. Alle informatie die deze cookies verzamelen wordt geaggregeerd en is daarom anoniem. Als u deze cookies niet toestaat, weten wij niet wanneer u onze site heeft bezocht.",
  cookieTableTitle2: "Prestatiecookies",
  cookieHeading13: "Cookiesubgroep",
  cookieHeading14: "Cookies",
  cookieHeading15: "Gebruikte cookies",
  cookieHeading16: "Levensduur",
  cookieHeading16Text16: "agc-glass.eu",
  cookieHeading16Text17:
    "<a class='text-[#007DDF] underline' href='https://www.agc-glass.eu/en' target='_blank'>www.agc-glass.eu</a>",
  cookieHeading16Text18:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/nr-data.net' target='_blank'>nr-data.net</a>",
  cookieHeading16Text19:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/cookies/_ga' target='_blank'>_gid , _ga</a>",
  cookieHeading16Text20:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/cookies/_ga_CHXGVTZGC2' target='_blank'>_ga_CHXGVTZGC2 , ln_or</a>",
  cookieHeading16Text21: "JSESSIONID",
  cookieHeading16Text22: "Direct",
  cookieHeading16Text23: "Direct",
  cookieHeading16Text24: "Indirect",
  cookieHeading16Text25: "Enkele seconden, 729 Dagen",
  cookieHeading16Text26: "Enkele seconden, Enkele seconden",
  cookieHeading16Text27: "Sessie",
  cookieHeading17: "Functionele cookies",
  cookieHeading17Text28:
    "Deze cookies stellen de website in staat om extra functies en persoonlijke instellingen aan te bieden. Ze kunnen door ons worden ingesteld of door externe aanbieders van diensten die we op onze pagina’s hebben geplaatst. Als u deze cookies niet toestaat kunnen deze of sommige van deze diensten wellicht niet correct werken.",
  cookieTableTitle3: "Functionele cookies",
  cookieTableHeading18: "Cookiesubgroep",
  cookieTableHeading19: "Cookies",
  cookieTableHeading20: "Gebruikte cookies",
  cookieTableHeading21: "Levensduur",
  cookieTableHeading21Text29:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/vimeo.com' target='_blank'>vimeo.com</a>",
  cookieTableHeading21Text30:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/agc.gestmax.fr' target='_blank'>agc.gestmax.fr</a>",
  cookieTableHeading21Text31: "vuid, __cf_bm",
  cookieTableHeading21Text32: "AGC Glass Europe_alertcookies",
  cookieTableHeading21Text33: "Indirect",
  cookieTableHeading21Text34: "Indirect",
  cookieTableHeading21Text35: "729 Dagen, Enkele seconden",
  cookieTableHeading21Text36: "59 Dagen",
  cookieHeading22: "Doelgroepgerichte cookies",
  cookieHeading23Text37:
    "Deze cookies kunnen door onze adverteerders op onze website worden ingesteld. Ze worden wellicht door die bedrijven gebruikt om een profiel van uw interesses samen te stellen en u relevante advertenties op andere websites te tonen. Ze slaan geen directe persoonlijke informatie op, maar ze zijn gebaseerd op unieke identificatoren van uw browser en internetapparaat. Als u deze cookies niet toestaat, zult u minder op u gerichte advertenties zien. ",
  cookieTableTitle4: "Doelgroepgerichte cookies",
  cookieTableHeading24: "Cookiesubgroep",
  cookieTableHeading25: "Cookies",
  cookieTableHeading26: "Gebruikte cookies",
  cookieTableHeading27: "Levensduur",
  cookieTableHeading27Text38: "agc-glass.eu",
  cookieTableHeading27Text39:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/youtube.com' target='_blank'>youtube.com</a>",
  cookieTableHeading27Text40:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/www.facebook.com' target='_blank'>www.facebook.com</a>",
  cookieTableHeading27Text41:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/linkedin.com' target='_blank'>linkedin.com</a>",
  cookieTableHeading27Text42:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/www.linkedin.com' target='_blank'>www.linkedin.com</a>",
  cookieTableHeading27Text43:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/host/ads.linkedin.com' target='_blank'>ads.linkedin.com</a>",
  cookieTableHeading27Text44:
    "<a class='text-[#007DDF] underline' href='https://cookiepedia.co.uk/cookies/_fbp' target='_blank'>_fbp</a>",
  cookieTableHeading27Text45: "VISITOR_INFO1_LIVE, CONSENT, YSC",
  cookieTableHeading27Text46:
    "li_gc, lang, UserMatchHistory, bcookie, AnalyticsSyncHistory, lidc",
  cookieTableHeading27Text47: "bscookie",
  cookieTableHeading27Text48: "lang",
  cookieTableHeading27Text49: "Direct",
  cookieTableHeading27Text50: "Indirect",
  cookieTableHeading27Text51: "Indirect",
  cookieTableHeading27Text52: "Indirect",
  cookieTableHeading27Text53: "Indirect",
  cookieTableHeading27Text54: "Indirect",
  cookieTableHeading27Text55: "89 Dagen",
  cookieTableHeading27Text56: "179 Dagen, 729 Dagen, Sessie",
  cookieTableHeading27Text57: "Sessie",
  cookieTableHeading27Text58:
    "729 Dagen, Sessie, 29 Dagen, 730 Dagen, 29 Dagen, Enkele seconden",
  cookieTableHeading27Text59: "730 Dagen",
  cookieTableHeading27Text60: "Sessie",
  cookieHeading28: "Wat met andere tracking technologieën, zoals web beacons?",
  cookieHeading28Text61:
    "Cookies zijn niet de enige manier om bezoekers van een website te herkennen of te volgen. Van tijd tot tijd kunnen we andere, gelijkaardige technologieën gebruiken, zoals web beacons (soms 'tracking pixels' of 'clear gifs' genoemd). Dit zijn kleine grafische bestanden die een unieke identificator bevatten die ons toelaten om te herkennen wanneer iemand onze Website heeft bezocht of een e-mail heeft geopend die we hem of haar hebben gestuurd. Dit laat ons toe om, bijvoorbeeld, [het internetverkeer van gebruikers van de ene pagina op onze Website naar een andere te volgen, cookies aan te leveren of ermee te communiceren, te begrijpen of u naar onze Website bent gekomen vanop een online advertentie die getoond wordt op een derde partij website, de prestatie van onze Website te verbeteren, en om het succes te meten van e-mail marketing campagnes]. In veel gevallen zullen deze technologieën afhankelijk zijn van cookies om juist te kunnen functioneren, en dus zal het weigeren van cookies een effect hebben op hun werking.",
  cookieHeading29: "Wat met gerichte reclame? ",
  cookieHeading29Text62:
    "Derde partijen kunnen cookies bedienen op uw computer of mobiel apparaat om reclame te tonen via onze Website. Deze bedrijven kunnen informatie gebruiken over uw bezoeken aan deze Website of andere websites om relevante advertenties over goederen en diensten waarin u mogelijks geïnteresseerd bent te weergeven. Ze kunnen ook gebruik maken van technologie die gebruikt wordt om de doeltreffendheid van advertenties te meten. Dit kan worden bereikt door gebruik te maken van cookies of web beacons om informatie te verzamelen over uw bezoeken aan deze Website en andere websites om relevante advertenties te tonen over goederen en diensten waarin u mogelijks geïnteresseerd bent. De informatie die door dit proces wordt verzameld laat ons of hen niet toe om uw naam, contactgegevens of andere persoonlijke identificeerbare gegevens te identificeren, tenzij u ervoor kiest om deze te verstrekken.",
  cookieHeading30: "Hoe kan ik cookies controleren?  ",
  cookieHeading30Text63:
    "U beschikt over het recht om te beslissen of u cookies aanvaardt of weigert. U kan uw cookie voorkeuren uitoefenen door te klikken op de relevante opt-out links die opgenomen zijn in het tabblad voor de privacy-instellingen op uw scherm. De opt-out voorkeur is 30 dagen geldig. Na deze periode kan u uw beslissing omkeren.     ",
  cookieHeading30Text64:
    "U kunt uw web browser instellingen instellen of wijzigen om cookies te aanvaarden of te weigeren. Indien u ervoor kiest om cookies te weigeren, kan u nog steeds onze Website gebruiken al kan uw toegang tot bepaalde functionaliteiten en delen van onze Website beperkt worden. Aangezien de middelen waarmee u cookies kan weigeren via uw web browser instellingen verschillen van browser tot browser, dient u voor meer informatie het hulp menu van uw browser te raadplegen. ",
  cookieHeading30Text65:
    "Daarenboven bieden de meeste advertentienetwerken u een mogelijkheid om u af te melden voor gerichte reclame. Indien u meer informatie wenst te verkrijgen, gelieve  <a class='text-[#007DDF] underline' href='http://www.aboutads.info/choices/' target='_blank'>http://www.aboutads.info/choices/</a> of <a class='text-[#007DDF] underline' href='http://www.youronlinechoices.com/fr/' target='_blank'>http://www.youronlinechoices.com/fr/</a> te raadplegen.",
  cookieHeading31: "De cookies die in het verleden werden gestuurd",
  cookieHeading31Text66:
    "Indien u een of meer cookies heeft uitgeschakeld, kunnen we nog steeds informatie gebruiken die verzameld werd door deze cookies voordat de cookie werd uitgeschakeld. We zullen echter ophouden met informatie te verzamelen via de opted-out cookie.",
  cookieHeading32: "Meer informatie over cookies",
  cookieHeading32Text67:
    "Voor verdere informatie over cookies, met inbegrip van uitleg over hoe cookies worden geplaatst op uw toestel, of hoe u deze kan beheren of verwijderen, raadpleeg <a class='text-[#007DDF] underline' href=' http://www.allaboutcookies.org/fr/' target='_blank'> http://www.allaboutcookies.org/fr/</a>.",
  cookieHeading33: "Wijzigingen aan deze Policy",
  cookieHeading33Text68:
    "Deze Policy kan van tijd tot tijd gewijzigd worden om aangepast te worden aan juridische, technische of commerciële wijzigingen. Wanneer we onze Policy zouden wijzigen, zullen we de gepaste maatregelen nemen om u hierover te informeren, aangepast aan het belang van de wijzigingen die we doorvoeren. Indien en waar vereist door de toepasselijke wetgeving betreffende gegevensbescherming, zullen wij uw toestemming vragen voor materiële wijzigingen aan deze Policy. ",
  cookieHeading33Text69:
    "U kunt zien wanneer deze Policy voor het laatst werd gewijzigd, door de 'laatst gewijzigd op' datum te bekijken die bovenaan deze Policy wordt getoond.    ",
  // result page
  resultTitle1: "Simulatie",
  resultTitle2: "Albertlaan 39 3210 Leuven",
  resultText1: "Build Year:",
  resultText2: "1954",
  resultText3: "Number of people living:",
  resultText4: "4",
  resultText5: "Surface:",
  resultText6: "209m",
  resultText7: "Heated levels:",
  resultText8: "3",
  resultText9: "House typology:",
  resultText10: "Detached",
  resultText11: "Basement:",
  resultText12: "Yes",
  resultText13: "Attic:",
  resultText14: "No",
  resultText15: "High energy consumer:",
  resultText16: "Electric car",
  resultText17: "Meer info",
  resultText18:
    "JE EPC-score lijkt zeer slecht. Jouw woning leidt zeer veel energieverlies.",
  resultText19: "Pas jouw gegevens aan om betere aanbevelingen te krijgen.",
  resultText20: "Walls insulation",
  resultText21: "Cavity (<2000)",
  resultText22: "Heating system",
  resultText23: "Gas (condensing)",
  resultText24: "Solar panels",
  resultText25: "Yes, 10 panels",
  resultText26: "Windows & doors",
  resultText27: "Old double (<2000)",
  resultText28: "Roof insulation",
  resultText29: "Exterior (>2000 & < 2010)",
  resultText30: "Ventilation",
  resultText31: "System C+",
  resultText32: "Huidig comfort",
  resultButton1: "Gegevens aanpassen",
  resultText33:
    "Kies je renovatiepakket richting Label A of Label B of pas het aan.",
  resultButton2: "Pakket label A",
  resultButton3: "Pakket label B",
  resultButton4: "Personaliseer",
  resultText34:
    "Deze combinatie van maatregelen werd geselecteerd om <span class='text-[#455259] font-bold text-sm'>zo kostenefficiënt mogelijk het EPC label B te bereiken. Het vereist niettemin de bevestiging van onze energie-expert</span> aangezien onze berekeningen gebaseerd zijn op ongevalideerde aannames en deze daarom enkel als indicatief kunnen beschouwd worden. Ook kan het voorgestelde pakket mogelijks niet consistent zijn met de maatregelen nodig voor een toekomstig traject naar label A. Onze energie-expert kan je een gedetailleerd renovatieadvies geven, rekening houdende met de werkelijke toestand van jouw huis, jouw huidig verbruik en andere vereisten zoals comfort bijvoorbeeld.",
  resultText35: "Subsidies section",
  resultText36:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea",
  resultButton5: "Subsidies 1",
  resultButton6: "Subsidies 2",
  resultText37: "ONS AANBEVOLEN PAKKET VOOR LABEL B",
  resltSelect1: "sorteren op",
  resultText38: "Solar panels",
  resultText39: "Subsidies",
  resultText40: "- 1.295 €",
  resultText41: "5.100 €",
  resultText42: "Before subidies",
  resultText43: "Savings",
  resultText44: "Complexity",
  resultText45: "Comfort",
  resultText46: "Window double",
  resultText47: "Subsidies",
  resultText48: "- 751 €",
  resultText49: "28.200 €",
  resultText50: "Before subidies",
  resultText51: "Roof insulation",
  resultText52: "Subsidies",
  resultText53: "- 441 €",
  resultText54: "8.400 €",
  resultText55: "Before subidies",
  resultText56: "Wall insulation",
  resultText57: "Subsidies",
  resultText58: "- 950 €",
  resultText59: "7.600 €",
  resultText60: "Before subidies",
  resultText61: "Renovation loan",
  resultText62: "Rate",
  resultText63: "Green loan",
  resultText64: "5,00 %",
  resultText65: "Loan amount",
  resultText66: "40.547,35 €",
  resultText67: "Term",
  resultText68: "12 months",
  resultText69: "60 months",
  resultText70:
    "<span class='text-[#007DDF] text-[22px] font-bold'>442,50 €</span>/month",
  resultText71: "Attention, borrowing money also costs money.",
  resultText72: "Jouw toekomstige EPC-Label",
  resultText73: "Wat je momenteel teveel betaalt",
  resultText74: "- 476 €",
  resultText75: "Jouw toekomstig comfort",
  resultText76: "+34 %",
  resultText77: "Renting value",
  resultText78: "950,00 €",
  resultText79: "Yearly savings",
  resultText80: "+34 %",
  resultText81: "Selling value",
  resultText82: "978 kg.",
  resultText83: "CO2 savings",
  resultText84: "Onze voorlopige schatting voor jouw woning",
  resultText85: "Begroting ",
  resultText86: "41.700 € ",
  resultText87: "Fluvius Subsidies",
  resultText88: "- 2.487 € ",
  resultText89: "Subsidies La Louvière ",
  resultText90: "- 50% ",
  resultText91: "Totaal (btw incl.)",
  resultText92: "-5.774 €",
  resultText93: "Boek een gratis en vrijblijvend audit",
  resultText94:
    "Bovenstaande informatie is <span class='text-sm text-[#455259] font-bold'>indicatief</span> en zal tijdens de audit door onze energiedeskundige worden bevestigd.",
  resultText95:
    " Deze eerste subsidieraming omvat alleen Fluvius-subsidies. Je zal je investering waarschijnlijk <span class='text-sm text-[#455259] font-bold'> nog verder verminderen dankzij andere subsidies en premies</span> (het kan oplopen tot 40% of zelfs meer)",
  resultText96: "Jouw volgende stappen",
  resultText97:
    "Tot eind april, kan je een audit reserveren met een energie-expert. Die zal de diagnose gedetailleerd afwerken.",
  resultText98: "Check je woning online",
  resultText99: "Boek een gratis audit",
  resultText100: "Vraag een vrijblijvende offerte aan",
  resultText101: "Doe mee met de buurtrenovatie",
  resultText102: "Renoveer",
  // footer
  footerText1: "Designed & developed door Agifly",
  footerText2: "Privacyverklaring",
  footerText3: "Cookiesbeleid",
};
export default german;
