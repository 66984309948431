import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
// import_images
import CookiePic from "../../assets/images/cookiePolicy/cookie_color.svg";

interface CookieInterface {}

const Cookie: FC<CookieInterface> = () => {
  // global_states
  const { state }: any = useContext(GlobalContext);

  return (
    <div>
      {/* <!-- CookiePro Cookies List start --> */}
      {/* <div id="ot-sdk-cookie-policy"></div> */}
      {/* <!-- CookiePro Cookies List end --> */}
      <div className="mx-[10%] lg:mx-[20%] pt-14">
        <div>
          {/* image_heading_text_div */}
          <div>
            <div className="flex items-center">
              <div className="h-fit">
                <img src={CookiePic} alt="Cookie" className="h-6 md:h-full" />
              </div>
              <h2 className="text-[#007DDF] text-lg md:text-[22px] font-bold pl-5">
                {state?.language?.cookieHeading}
              </h2>
            </div>
            <div className="py-6">
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading1}
              </h2>
              <ul className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] list-disc pl-12">
                <li
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.cookieHeading1List1,
                  }}
                />
                <li
                  className="py-3"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.cookieHeading1List2,
                  }}
                />
              </ul>
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading2}
              </h2>
              <ul className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] list-disc pl-12">
                <li
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.cookieHeading2List3,
                  }}
                />
                <li
                  className="py-3"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.cookieHeading2List4,
                  }}
                />
                <li
                  className="pb-3"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.cookieHeading2List5,
                  }}
                />
              </ul>
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading3}
              </h2>
              <ul className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] list-disc pl-12">
                <li
                  className="pb-3"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.cookieHeading3List6,
                  }}
                />
              </ul>
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading4}
              </h2>
              {/* CookiePro Cookies List start */}
              <div id="ot-sdk-cookie-policy"></div>
              {/* CookiePro Cookies List end */}
              {/* <h2 className="text-[#007DDF] text-xs md:text-sm font-bold pb-3">
                {state?.language?.cookieHeading5}
              </h2>
              <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3 ">
                {state?.language?.cookieHeading5Text1}
              </p>
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading6}
              </h2>
              <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3">
                {state?.language?.cookieHeading6Text2}
              </p>
              <div className="overflow-x-auto py-3">
                <table>
                  <tr>
                    <th>{state?.language?.cookieTableTitle1}</th>
                  </tr>
                  <tr>
                    <th>{state?.language?.cookieTableHeading7}</th>
                    <th>{state?.language?.cookieTableHeading8}</th>
                    <th>{state?.language?.cookieTableHeading9}</th>
                    <th>{state?.language?.cookieTableHeading10}</th>
                  </tr>
                  <tr>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieTableHeading10Text3,
                      }}
                    ></td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieTableHeading10Text6,
                      }}
                    ></td>
                    <td>{state?.language?.cookieTableHeading10Text9}</td>
                    <td>{state?.language?.cookieTableHeading10Text12}</td>
                  </tr>
                  <tr>
                    <td>{state?.language?.cookieTableHeading10Text4}</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieTableHeading10Text7,
                      }}
                    ></td>
                    <td>{state?.language?.cookieTableHeading10Text10}</td>
                    <td>{state?.language?.cookieTableHeading10Text13}</td>
                  </tr>
                  <tr>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieTableHeading10Text5,
                      }}
                    ></td>
                    <td>{state?.language?.cookieTableHeading10Text8}</td>
                    <td>{state?.language?.cookieTableHeading10Text11}</td>
                    <td>{state?.language?.cookieTableHeading10Text14}</td>
                  </tr>
                </table>
              </div>
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading11}
              </h2>
              <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3 ">
                {state?.language?.cookieHeading11Text15}
              </p>
              <div className=" overflow-x-auto py-3">
                <table>
                  <tr>
                    <th>{state?.language?.cookieTableTitle2}</th>
                  </tr>
                  <tr>
                    <th>{state?.language?.cookieHeading13}</th>
                    <th>{state?.language?.cookieHeading14}</th>
                    <th>{state?.language?.cookieHeading15}</th>
                    <th>{state?.language?.cookieHeading16}</th>
                  </tr>
                  <tr>
                    <td>{state?.language?.cookieHeading16Text16}</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieHeading16Text19,
                      }}
                    ></td>
                    <td>{state?.language?.cookieHeading16Text22}</td>
                    <td>{state?.language?.cookieHeading16Text25}</td>
                  </tr>
                  <tr>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieHeading16Text17,
                      }}
                    ></td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieHeading16Text20,
                      }}
                    ></td>
                    <td>{state?.language?.cookieHeading16Text23}</td>
                    <td>{state?.language?.cookieHeading16Text26}</td>
                  </tr>
                  <tr>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieHeading16Text18,
                      }}
                    ></td>
                    <td>{state?.language?.cookieHeading16Text21}</td>
                    <td>{state?.language?.cookieHeading16Text24}</td>
                    <td>{state?.language?.cookieHeading16Text27}</td>
                  </tr>
                </table>
              </div>
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading17}
              </h2>
              <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3">
                {state?.language?.cookieHeading17Text28}
              </p>
              <div className=" overflow-x-auto py-4">
                <table>
                  <tr>
                    <th>{state?.language?.cookieTableTitle3}</th>
                  </tr>
                  <tr>
                    <th>{state?.language?.cookieTableHeading18}</th>
                    <th>{state?.language?.cookieTableHeading19}</th>
                    <th>{state?.language?.cookieTableHeading20}</th>
                    <th>{state?.language?.cookieTableHeading21}</th>
                  </tr>
                  <tr>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieTableHeading21Text29,
                      }}
                    ></td>
                    <td>{state?.language?.cookieTableHeading21Text31}</td>
                    <td>{state?.language?.cookieTableHeading21Text33}</td>
                    <td>{state?.language?.cookieTableHeading21Text35}</td>
                  </tr>
                  <tr>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieTableHeading21Text30,
                      }}
                    ></td>
                    <td>{state?.language?.cookieTableHeading21Text32}</td>
                    <td>{state?.language?.cookieTableHeading21Text34}</td>
                    <td>{state?.language?.cookieTableHeading21Text36}</td>
                  </tr>
                </table>
              </div>
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading22}
              </h2>
              <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3">
                {state?.language?.cookieHeading23Text37}
              </p>
              <div className="overflow-x-auto py-3">
                <table>
                  <tr>
                    <th>{state?.language?.cookieTableTitle2}</th>
                  </tr>
                  <tr>
                    <th>{state?.language?.cookieTableHeading24}</th>
                    <th>{state?.language?.cookieTableHeading25}</th>
                    <th>{state?.language?.cookieTableHeading26}</th>
                    <th>{state?.language?.cookieTableHeading27}</th>
                  </tr>
                  <tr>
                    <td>{state?.language?.cookieTableHeading27Text38}</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieTableHeading27Text44,
                      }}
                    ></td>
                    <td>{state?.language?.cookieTableHeading27Text49}</td>
                    <td>{state?.language?.cookieTableHeading27Text55}</td>
                  </tr>
                  <tr>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieTableHeading27Text39,
                      }}
                    ></td>
                    <td>{state?.language?.cookieTableHeading27Text45}</td>
                    <td>{state?.language?.cookieTableHeading27Text50}</td>
                    <td>{state?.language?.cookieTableHeading27Text56}</td>
                  </tr>
                  <tr>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieTableHeading27Text40,
                      }}
                    ></td>
                    <td></td>
                    <td>{state?.language?.cookieTableHeading27Text51}</td>
                    <td>{state?.language?.cookieTableHeading27Text57}</td>
                  </tr>
                  <tr>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieTableHeading27Text41,
                      }}
                    ></td>
                    <td>{state?.language?.cookieTableHeading27Text46}</td>
                    <td>{state?.language?.cookieTableHeading27Text52}</td>
                    <td>{state?.language?.cookieTableHeading27Text58}</td>
                  </tr>
                  <tr>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieTableHeading27Text42,
                      }}
                    ></td>
                    <td>{state?.language?.cookieTableHeading27Text47}</td>
                    <td>{state?.language?.cookieTableHeading27Text53}</td>
                    <td>{state?.language?.cookieTableHeading27Text59}</td>
                  </tr>
                  <tr>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: state?.language?.cookieTableHeading27Text43,
                      }}
                    ></td>
                    <td>{state?.language?.cookieTableHeading27Text48}</td>
                    <td>{state?.language?.cookieTableHeading27Text54}</td>
                    <td>{state?.language?.cookieTableHeading27Text60}</td>
                  </tr>
                </table>
              </div> */}
              {/* below yellow */}
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading28}
              </h2>
              <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3">
                {state?.language?.cookieHeading28Text61}
              </p>
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading29}
              </h2>
              <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3">
                {state?.language?.cookieHeading29Text62}
              </p>
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading30}
              </h2>
              <ul className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] list-disc pl-12">
                <li> {state?.language?.cookieHeading30Text63}</li>
                <li className="py-3">
                  {" "}
                  {state?.language?.cookieHeading30Text64}
                </li>
                <li
                  className="pb-3"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.cookieHeading30Text65,
                  }}
                />
              </ul>
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading31}
              </h2>
              <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3 ">
                {state?.language?.cookieHeading31Text66}
              </p>
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading32}
              </h2>
              <p
                className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3"
                dangerouslySetInnerHTML={{
                  __html: state?.language?.cookieHeading32Text67,
                }}
              />
              <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                {state?.language?.cookieHeading33}
              </h2>
              <ul className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] list-disc pl-12">
                <li> {state?.language?.cookieHeading33Text68}</li>
                <li className="py-3">
                  {" "}
                  {state?.language?.cookieHeading33Text69}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Cookie;
