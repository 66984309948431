import React, { FC } from "react";
import Banner from "../../components/chooseProject/banner";
interface HomePageInterface {}

const HeaderFilter: FC<HomePageInterface> = () => {
  return (
    <div className="bg-home ">
      <Banner />
    </div>
  );
};

export default HeaderFilter;
