import axios from "axios";

// GET API CAll
export const GetAPIService = (Url: any) => {
  return axios.get(process.env.REACT_APP_FRONTEND_URL + Url);
};
// Post API CAll
export const PostAPIService = (Url: any, payload: any) => {
  return axios.post(process.env.REACT_APP_FRONTEND_URL + Url, payload);
};
// Delete API CAll
export const DeleteAPIService = (Url: any, payload: any) => {
  return axios.delete(process.env.REACT_APP_FRONTEND_URL + Url, payload);
};
// Hubspot Post API CAll
export const HubspotPostAPIService = (Url: any, payload: any) => {
  return axios.post(process.env.REACT_APP_HUBSPOT_FRONTEND_URL + Url, payload);
};