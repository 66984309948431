import React, { createContext, useReducer } from "react";
import AppReducer from "../reducer/appReducer";
// language file
import Fr from "../../languages/fr";
// Initial state
const initialState: any = {
  // language (default French)
  language: Fr,
  // state to store previous page name when going to policy and cookie page
  previousPage: [],
  // state to stor all home pages data
  homePageData: {},
  // backofficeContent
  backofficeContent: {},
  // backoffice Content loader
  backofficeContentLoader: true,
  // bookAnAuditModal
  bookAnAuditModal: false,
  // getAllAdresses
  getAllAdresses:[]
};

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
