import React, { useContext } from "react";
import { Input, AutoComplete } from "antd";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { getValue } from "../../utils/getValue";
import Reno from "../../assets/logo/homeIcon.svg";

// interface SearchedData {
//   options?: any;
//   dropdownClassName?: any;
//   onSelect?: any;
//   onSearch?: any;
//   notFoundContent?: any;
//   onFocus?: any;
//   placeholder?: any;
//   buttonText?: any;
// }

const AutoCompleteSearch = (props: any) => {
  const {
    options,
    dropdownClassName,
    onSelect,
    onSearch,
    notFoundContent,
    selectAddressHandler,
    onFocus,
    placeholder,
    buttonText,
  } = props;
  // const { state } = useContext(GlobalContext);
  return (
    <>
      <div
        className={`w-full autoSearch ttnc-autocomplete-search relative flex items-center text-paragraph-base rounded-lg result_common_model_container_header login_model_container 
        `}
      >
        {/* sreach Icon button */}
        {/* <div
          className=""
          onClick={() => {
            selectAddressHandler();
          }}
        > */}
        <button
          onClick={() => {
            selectAddressHandler();
          }}
          className="bg-[#007DDF] text-white autoComplete_search_button absolute right-0 z-[100] p-2 text-white mt-[5px] mr-[5px] rounded-[3px]"
        >
          <span>{buttonText}</span>
        </button>
        {/* <img
            src={Reno}
            alt="icon"
            className=""
            onClick={() => {
              selectAddressHandler();
            }}
          /> 
        </div>
        */}
        {/* responsive_class_for_mobile */}
        <div className={`mt-0.5 relative w-full z-20`}>
          <AutoComplete
            dropdownMatchSelectWidth={252}
            options={options}
            dropdownClassName={dropdownClassName}
            onSelect={onSelect}
            onSearch={onSearch}
            notFoundContent={"Pas d’adresse correspondante"}
            onFocus={onFocus}
          >
            <Input.Search
              className="w-full"
              size="large"
              placeholder={placeholder}
              enterButton={""}
            />
          </AutoComplete>
        </div>
      </div>
    </>
  );
};
export default AutoCompleteSearch;
