import React, { FC, useContext, useEffect } from "react";
import { Checkbox, Modal } from "antd";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import { getValue } from "../../utils/getValue";

interface UserRegisterationModalInterface {
  visible?: boolean;
  setVisible?: any;
}
const UserRegisterationModal: FC<UserRegisterationModalInterface> = ({
  visible,
  setVisible,
}) => {
  // global state
  const { state }: any = useContext(GlobalContext);

  // hubspot form //
  useHubspotForm({
    portalId: "25186371",
    formId: "4540b61c-5bb3-4961-848b-76221dbd8fa4",
    target: "#my-hubspot-form2",
  });

  // useEffect to fix ResizeObserver loop limit exceeded issue
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  return (
    <div className="flex justify-center">
      <div>
        <Modal
          open={visible}
          onCancel={() => setVisible(false)}
          // onOk={() => console.log("Saved!")}
          closable={false}
          footer={null}
          className="!w-[90%] lg:!w-[70%] xl:!w-[50%]"
          maskClosable={false}
        >
          <h2
            className="text-[#007DDF] text-center text-lg md:text-[20px] lg:text-[38px] font-bold font-poppins p-8"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 0, 146)?.props?.content,
            }}
          >
            {/* {state?.language?.signupModalTitle} */}
          </h2>
          <div className="px-6 md:px-24 lg:px-[130px] pb-6">
            <p
              className="text-center text-sm text-[#455259]  font-normal  font-poppins  pb-5"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 0, 147)?.props?.content,
              }}
            ></p>
            {/* form div */}
            <div>
              <div id="my-hubspot-form2"></div>
            </div>
          </div>
          <div className="border border-[#F0F0F0] "></div>
          <div className="flex justify-between p-5 items-center">
            <div>
              <button
                onClick={() => setVisible(false)}
                className="text-base font-poppins text-[#989898] bg-white underline"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 148)?.props?.content,
                  }}
                ></span>
              </button>
            </div>

            {/* <div>
              <button
                className="text-base font-poppins text-white bg-[#007DDF] border rounded py-2.5 px-7"
                onClick={() => setVisible(false)}
              >
                Verstuur
              </button>
            </div> */}
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default UserRegisterationModal;
