import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";

interface PolicyInterface {}

const Policy: FC<PolicyInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);
  return (
    <div>
      <div className="mx-[10%] lg:mx-[20%] pt-14">
        <div>
          <div>
            <div className="flex items-center">
              <h2 className="text-[#007DDF] text-lg md:text-[22px] font-bold ">
                {state?.language?.policyHeading1}
              </h2>
            </div>
            <div className="py-4">
              <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] ">
                {state?.language?.policyText1}
              </p>
              <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] py-2">
                {state?.language?.policyText2}
              </p>

              <ul className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] list-disc pl-12 cursor-pointer">
                <li
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText2List1,
                  }}
                />

                <li
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText2List2,
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText2List3,
                  }}
                />
              </ul>
              <p
                className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] py-3"
                dangerouslySetInnerHTML={{
                  __html: state?.language?.policyText3,
                }}
              ></p>
              <p
                className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] py-3 "
                dangerouslySetInnerHTML={{
                  __html: state?.language?.policyText4,
                }}
              ></p>
              <p
                className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] "
                dangerouslySetInnerHTML={{
                  __html: state?.language?.policyText5,
                }}
              >
                {/* {state?.language?.policyText5} */}
              </p>
              <ol className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pl-12">
                <li
                  className="py-3"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText5List4,
                  }}
                ></li>
                <li
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText5List5,
                  }}
                ></li>
                <li
                  className="py-3"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText5List6,
                  }}
                ></li>
              </ol>
              <div>
                <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                  {state?.language?.policyHeading2}
                </h2>
                <p
                  className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] "
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText6,
                  }}
                />
                <p
                  className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] py-2"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText7,
                  }}
                />
                <ul className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] list-disc pl-12">
                  <li>{state?.language?.policyText7List7}</li>
                  <li className="py-3">{state?.language?.policyText7List8}</li>
                  <li className="pb-3">{state?.language?.policyText7List9}</li>
                </ul>
                <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                  {state?.language?.policyHeading3}
                </h2>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px]">
                  {state?.language?.policyText8}
                </p>
                <ul className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] list-disc pl-12">
                  <li className="py-3">{state?.language?.policyText8List10}</li>
                  <li>{state?.language?.policyText8List11}</li>
                  <li className="py-3">{state?.language?.policyText8List12}</li>
                  <li>{state?.language?.policyText8List13}</li>
                  <li className="py-3">{state?.language?.policyText8List14}</li>
                  <li>{state?.language?.policyText8List15}</li>
                  <li className="py-3">{state?.language?.policyText8List16}</li>
                  <li>{state?.language?.policyText8List17}</li>
                  <li className="py-3">{state?.language?.policyText8List18}</li>
                </ul>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px]">
                  {state?.language?.policyText9}
                </p>
                <p
                  className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] py-3"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText10,
                  }}
                />
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3">
                  {state?.language?.policyText11}
                </p>
                <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                  {state?.language?.policyHeading3A}
                </h2>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px]">
                  {state?.language?.policyText11A}
                </p>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] py-3">
                  {state?.language?.policyText11B}
                </p>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3">
                  {state?.language?.policyText11C}
                </p>

                <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                  {state?.language?.policyHeading4}
                </h2>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px]">
                  {state?.language?.policyText12}
                </p>
                <p
                  className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] py-3"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText13,
                  }}
                ></p>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px]">
                  {state?.language?.policyText14}
                </p>
                <ol className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pl-12">
                  <li
                    className="py-3"
                    dangerouslySetInnerHTML={{
                      __html: state?.language?.policyText14List19,
                    }}
                  ></li>
                </ol>
                <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                  {state?.language?.policyHeading5}
                </h2>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px]">
                  {state?.language?.policyText15}
                </p>
                <ul className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] list-disc pl-12">
                  <li className="py-3">
                    {state?.language?.policyText15List20}
                  </li>
                  <li>{state?.language?.policyText15List21}</li>
                  <li className="py-3">
                    {state?.language?.policyText15List22}
                  </li>
                  <li>{state?.language?.policyText15List23}</li>
                </ul>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] py-3">
                  {state?.language?.policyText16}
                </p>
                <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                  {state?.language?.policyHeading6}
                </h2>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3">
                  {state?.language?.policyText17}
                </p>
                <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                  {state?.language?.policyHeading7}
                </h2>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px]">
                  {state?.language?.policyText18}
                </p>
                <ul className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] list-disc pl-12">
                  <li className="py-3">
                    {state?.language?.policyText18List24}
                  </li>
                  <li>{state?.language?.policyText18List25}</li>
                  <li className="py-3">
                    {state?.language?.policyText18List26}
                  </li>
                  <li>{state?.language?.policyText18List27}</li>
                  <li
                    className="py-3"
                    dangerouslySetInnerHTML={{
                      __html: state?.language?.policyText18List28,
                    }}
                  ></li>
                  <li>{state?.language?.policyText18List29}</li>
                </ul>
                <p
                  className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] py-2"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText19,
                  }}
                />
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px]">
                  {state?.language?.policyText20}
                </p>
                <ol className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pl-12">
                  <li
                    className="py-3"
                    dangerouslySetInnerHTML={{
                      __html: state?.language?.policyText20List30,
                    }}
                  ></li>
                </ol>
                <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                  {state?.language?.policyHeading8}
                </h2>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] pb-3">
                  {state?.language?.policyText21}
                </p>
                <h2 className="text-[#007DDF] text-xs md:text-sm font-bold py-3">
                  {state?.language?.policyHeading9}
                </h2>
                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px]">
                  {state?.language?.policyText22}
                </p>
                <p
                  className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] py-3"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText23,
                  }}
                />

                <p className="text-[#455259] text-xs md:text-sm font-normal leading-[22px]">
                  {state?.language?.policyText24}
                </p>
                <p
                  className="text-[#455259] text-xs md:text-sm font-normal leading-[22px] py-3"
                  dangerouslySetInnerHTML={{
                    __html: state?.language?.policyText25,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Policy;
