import React, { FC, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/globalContext/globalContext";
// components
import SelectorLanguage from "../../shared/languageSelector";
import { Spin } from "antd";
// images
import Renocity from "../../assets/images/homepage/renoCity.svg";
import Aalts from "../../assets/images/homepage/Aalst.png";
import Lalou from "../../assets/images/homepage/lalouviere.png";
import { useNavigate } from "react-router-dom";

interface HomePageInterface {}

const HeaderFilter: FC<HomePageInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);
  console.log("state", state);
  const Navigate = useNavigate();
  return (
    <div className="h-full">
      {/* container_div */}
      <div className="mx-0 lg:mx-[10%] px-5 py-10">
        {/* header_div */}
        <div>
          {/* header_image_text_Nl_div */}
          <div className="flex flex-row items-center justify-between">
            <div>
              <div className="pb-1 2xl:pb-2">
                <p className="text-[#77DB89] text-[10px] md:text-[16px] 2xl:text-[22px] ">
                  {state?.backofficeContent?.logo_tip_1}
                </p>
              </div>
              <div>
                <img
                  src={Renocity}
                  // onClick={() => Navigate("/home")}
                  alt="renoCity"
                  className="h-[25px] md:h-[45px] 2xl:h-[52px] cursor-pointer"
                />
              </div>
              <p className="text-[#6B7180] text-[6px] sm:text-[7px] md:text-[9px]  2xl:text-[16px] opacity-100 text-end">
                {state?.backofficeContent?.logo_tip_2}
              </p>
            </div>
            {/* NL component */}
            <div>
              <SelectorLanguage />
            </div>
          </div>
        </div>
        {state?.backofficeContentLoader ? (
          <div className="h-screen flex justify-center items-center">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {/* banner_div */}
            <div className="flex justify-center">
              <div className="w-11/12 lg:w-8/12">
                {/* heading_text_div */}
                <div className="pt-12">
                  <h1 className="text-[#007DDF] text-center text-[24px] lg:text-[38px] font-semibold ">
                    {state?.backofficeContent?.title}
                  </h1>
                </div>
                {/* text_div */}
                <div className="pt-[19px]">
                  <p
                    className="text-[14px] text-center text-[#455259]"
                    dangerouslySetInnerHTML={{
                      __html: state?.backofficeContent?.description,
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Boxes_div */}
            <div className="py-1 flex justify-center">
              <div className="w-full md:w-10/12 lg:w-8/12 flex  justify-between flex-wrap">
                <div
                  // onClick={() => window.open("https://hasselt.renocity.be","_self")}
                  className="rounded box-shadow1 w-full sm:w-[48%] lg:w-[42%] my-[20px] border-[#007DDF2B] border bg-white py-8 px-4 flex flex-col items-center"
                >
                  {/* image_div */}
                  <div className="h-[50px]">
                    <img src={Aalts} alt="" className="h-full" />
                  </div>
                  {/* text_div */}
                  <div className="pt-5">
                    <p
                      className="text-lg text-center font-normal text-[#000000]"
                      dangerouslySetInnerHTML={{
                        __html: state?.backofficeContent?.desc_1,
                      }}
                    ></p>
                  </div>
                </div>
                <div
                  // onClick={() => Navigate("/coming-soon")}
                  onClick={() =>
                    window.open(
                      "https://lalouviere.renocity.be/",
                      "_self"
                    )
                  }
                  className="cursor-pointer rounded box-shadow1 w-full sm:w-[48%] lg:w-[42%] my-[20px] border-[#007DDF2B] border bg-white py-8 px-4 flex flex-col items-center"
                >
                  {/* image_div */}
                  <div className="h-[50px]">
                    <img src={Lalou} alt="" className="h-full" />
                  </div>
                  {/* text_div */}
                  <div className="pt-5">
                    <p
                      className="text-lg text-center  font-normal text-[#000000]"
                      dangerouslySetInnerHTML={{
                        __html: state?.backofficeContent?.desc_2,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderFilter;
