const Reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };
    case "SET_PREVIOUS_PAGE":
      return {
        ...state,
        previousPage: action.payload,
      };
    case "SET_HOMEPAGE_DATA":
      return {
        ...state,
        homePageData: action.payload,
      };
    case "SET_BACKOFFICE_CONTENT":
      return {
        ...state,
        backofficeContent: action.payload,
      };
    case "SET_BACKOFFICE_CONTENT_LOADER":
      return {
        ...state,
        backofficeContentLoader: action.payload,
      };
    case "SET_BOOK_AN_AUDIT_MODAL":
      return {
        ...state,
        bookAnAuditModal: action.payload,
      };
    case "SET_ALL_ADDRESSES":
      return {
        ...state,
        getAllAdresses: action.payload,
      };
    default:
      return state;
  }
};
export default Reducer;
