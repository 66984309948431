import React, { FC } from "react";
import Cookie from "../../components/cookie/cookie";

interface CookiePolicyInterface {}

const CookiePolicy: FC<CookiePolicyInterface> = () => {
  return (
    <div>
      <Cookie />
    </div>
  );
};
export default CookiePolicy;
