import Routes from "./routes";
import { GlobalProvider } from "./context/globalContext/globalContext";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import "./styles/index.css";
// map
import { setDefaultOptions } from "esri-loader";
setDefaultOptions({ version: "4.22" });

const App = () => {
  return (
    <HubspotProvider>
      <GlobalProvider>
        <Routes />
      </GlobalProvider>
    </HubspotProvider>
  );
};

export default App;
